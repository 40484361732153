import React, { useMemo } from "react";
import {
	Cell,
	Row,
	SortingRule,
	TableState,
	usePagination,
	useSortBy,
	useTable,
} from "react-table";
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { Pagination } from "./Pagination";
import { fixAmp } from "../helpers";

const downIcon = (<ChevronDownIcon style={{ display: "inline", width: 12, height: 12, fill: "black" }} />);
const upIcon = (<ChevronUpIcon style={{ display: "inline", width: 12, height: 12, fill: "black" }} />);


export type CellFunc = ({ value, row }: { value: string | number, row: Row }) => JSX.Element;
export type GetRowClassNameFunc = (row: Row) => string;
export type GetCellClassNameFunc = (row: Row, cell: Cell) => string;

export interface ColumnType {
	Header: string;
	accessor: string;
	Cell?: CellFunc;
}

const customTableSort = (
	rowA: Row<object>,
	rowB: Row<object>,
	id: string,
	descending?: boolean
): number => {
	const rowAVal = rowA.values[id] || '';
	const rowBVal = rowB.values[id] || '';

	if (typeof rowAVal === 'number' && typeof rowBVal === 'number') {
		return rowAVal - rowBVal;
	}

	if (rowAVal < rowBVal) {
		return -1;
	} else if (rowAVal > rowBVal) {
		return 1;
	} else {
		return 0;
	}
};



type ReactTableProps = {
	data: object[];
	columns: ColumnType[];
	sortBy?: Array<SortingRule<object>>;
	paginate?: boolean; // flag to enable/disable pagination
	getRowClassName?: GetRowClassNameFunc;
	getCellClassName?: GetCellClassNameFunc;
	initPageSize?: number;
	className?: string;
	disableStriping?: boolean;
	disableBorder?: boolean;
	wrappable?: boolean;
};

const ifNull = (val: any, defaultVal: string): any => {
	if (val === null || val === undefined) {
		return defaultVal;
	} else {
		return val;
	}
}

const defaultCellFunc: CellFunc = ({ value }: { value: string | number }) => (
	<span>{fixAmp((ifNull(value, '')).toString())}</span>
);

export const createColsFromData = (data: object[]): ColumnType[] => {
	const allKeys = {} as {[key:string]: number};

	for (const row of data) {
		for (const key of Object.keys(row)) {
			allKeys[key] = 1;
		}		
	}

	return Object.keys(allKeys).map((key: string) => {
		return { Header: key, accessor: key };
	});
}

export const Table = ({
	data,
	columns,
	sortBy,
	paginate = true,
	getRowClassName,
	getCellClassName,
	initPageSize,
	className,
	disableStriping,
	disableBorder,
	wrappable = true,
}: ReactTableProps) => {
	const initialState: Partial<TableState<object>> = {};

	if (sortBy && sortBy?.length > 0) (initialState as any).sortBy = sortBy;
	(initialState as any).pageSize = initPageSize || 10;

	const fixedColumns = useMemo(() => {
		return columns.map(({ accessor, Header, Cell }) => {
			return {
				Header,
				accessor,
				Cell: Cell || defaultCellFunc,
				sortType: customTableSort
			};
		});
	}, [columns]);

	const tableInstance = useTable(
		{ initialState, columns: fixedColumns, data },
		useSortBy,
		usePagination
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
	} = tableInstance;

	const tableRows = paginate ? page : rows;

	const getImgSrc = (isSortedDesc?: boolean) => {
		const baseSrc = "/static/assets";
		const imgSrc =
			isSortedDesc === undefined
				? "/sort-none.svg"
				: isSortedDesc
					? "/sort-down.svg"
					: "/sort-up.svg";

		return `${baseSrc}${imgSrc}`;
	};

	return (
		<>
			<table {...getTableProps()} className={`text-sm w-full table-auto ${className}`}>
				<thead>
					{headerGroups.map((headerGroup, headerGroupIndex) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							key={headerGroupIndex}
							className="border-b border-gray-300"
						>
							{headerGroup.headers.map((column, columnIndex) => {
								const sortProps = column.getSortByToggleProps();
								const headerProps = column.getHeaderProps(sortProps);
								return (
									<th
										{...headerProps}
										key={columnIndex}
										className="select-none pr-4 text-left align-top"
									>
										<div className="flex">
											{/* TABLE HEADER */}
											<div className="pr-2">{column.render("Header")}</div>
											{/* SORT ICON */}
											{(column.isSortedDesc === undefined) ? "" : (column.isSortedDesc) ? downIcon : upIcon}
										</div>
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{tableRows.map((row: any, rowIndex: number) => {
						prepareRow(row);
						let rowClassName: string = '';
						let oddEvenClass = (!disableStriping) 
							? (rowIndex % 2 !== 0) ? "bg-gray-100" : "bg-white"
							: "";
						if (getRowClassName) {
							rowClassName = getRowClassName(row);
							oddEvenClass = "";
						}
						const borderClass = (!disableBorder)
							? "border-b border-gray-200"
							: "";
						return (
							<tr
								{...row.getRowProps()}
								key={rowIndex}
								className={`${borderClass} ${oddEvenClass} ${rowClassName}`}
							>
								{row.cells.map((cell: any, cellIndex: number) => {
									let cellClassName: string = '';
									if (getCellClassName) {
										cellClassName = getCellClassName(row, cell);
									}
									const wrapClassName = (wrappable)
										? ""
										: "whitespace-nowrap";

									return (
										<td
											{...cell.getCellProps()}
											key={cellIndex}
											className={`${cellClassName} ${wrapClassName} pb-2 pt-2`}
										>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			{paginate && <Pagination tableInstance={tableInstance} />}
		</>
	);
};
