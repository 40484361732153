import { NavLink } from "react-router-dom";
import { LINK_CLASS_NAME } from "./CellHelpers";


export interface StyleNavLinkProps {
    to: any;
    children: any;
    className?: string;
    target?: string;
}


export function StyleNavLink(props: StyleNavLinkProps) {
    const { to, children, className, target } = props;
    return (
        <>
            <NavLink className={LINK_CLASS_NAME + ` ${className ? className : ""}`} to={to} target={target} style={({ isActive }) => ({
                color: isActive ? "darkgray" : ""
            })}>
                {children}
            </NavLink>
        </>
    );
}

