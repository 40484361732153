import { ReactNode } from "react";


export interface ShowIfProps {
    show: boolean;
    children: any;
}

export function ShowIf(props: ShowIfProps) {
    const { show, children } = props;

    const condChildren = (show) ? children : <></>;

    return (
        <>
            {condChildren}
        </>
    )
}
