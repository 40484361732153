import { Outlet } from "react-router";
import { StyleNavLink } from "./StyleNavLink";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="h-full flex flex-col">
      <div className="mt-2 mb-2 flex-0">
        <span className="mr-2 ml-2">
          <StyleNavLink to={"/"}>All Tournaments</StyleNavLink>
        </span>
        <span className="mr-2 ml-2">
          <StyleNavLink to={"/top_fencers"}>Top Fencers</StyleNavLink>
        </span>
        <span className="mr-2 ml-2">
          <StyleNavLink to={"/reports"}>Reports</StyleNavLink>
        </span>
        <span className="mr-2 ml-2">
          <StyleNavLink to={"/tools"}>Tools</StyleNavLink>
        </span>
        <span className="mr-2 ml-2">
          <StyleNavLink to={"/simulator"}>Simulator</StyleNavLink>
        </span>
      </div>
      <Outlet />
    </div>
  );
}

export default App;
