import { ChangeEventHandler, MouseEventHandler, useState } from "react";
import { fencerCell } from "./CellHelpers";
import { Table, createColsFromData } from "./table";


export type SqlRow = Record<string, string | number>;


export interface CrawlUrlForElosResult {
    rows?: SqlRow[];
    fencerFields?: {[key:string]: string};
    errMessage?: string;
}


export function ToolCrawl() {
    const defaultUrl = "https://fencingtimelive.com/rounds/seeding/16CF370CE6BC4684AD4BD3D5C090D6E9/D1FFD1B599A845CAB8A2981CA6DB78CC";

    const [data, setData] = useState([] as SqlRow[]);
    const [fencerFields, setFencerFields] = useState({} as  {[key:string]: string});
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState('');
    const [crawlUrl, setCrawlUrl] = useState(defaultUrl);

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setCrawlUrl(event.target.value);
    }

    const onButtonClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
        console.log("Crawl", crawlUrl);

        setData([]);
        setErr('');
        setLoading(true);
        const postData = { crawlUrl };
        const response = await fetch("/api/toolCrawl", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(postData) });
        const json: CrawlUrlForElosResult = await response.json();
        console.log('toolCrawl', { postData, json });
        setLoading(false);
        if (json.errMessage) {
            setErr(json.errMessage);
        } else {
            setData(json.rows!);
            setFencerFields(json.fencerFields!);
        }
    }

    const onClearClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
        setCrawlUrl('');
    }

    const columns = createColsFromData(data);
    for (const oneFencerField of Object.keys(fencerFields)) {
        const col = columns.filter(col => col.accessor === oneFencerField)?.[0];
        if (col) {
            col.Cell = fencerCell;
        }
    }
    
    return (
        <div>
            <div>
                <div className="text-center">
                    <div className="mb-4">Crawl URL:</div>
                    <div className="mb-4">
                        <span className="border-black border-2 pb-1 pt-1 pl-1 pr-1">
                            <input className="w-96 border-none" type="text" id="crawl_url" value={crawlUrl} onChange={onInputChange} />
                        </span>
                    </div>
                    <div className="mb-4">
                        <button className="bg-gray-300 pt-2 pb-2 pr-2 pl-2 mr-2" onClick={onButtonClick}>Submit</button>
                        <button className="bg-gray-300 pt-2 pb-2 pr-2 pl-2 ml-2" onClick={onClearClick}>Clear</button>
                    </div>
                    <div className="mt-4 mb-4 text-sm">
                        Paste a URL above (into the Crawl URL input) to get a table with the input table with the fencers; ELOs added.  
                        For example, you could paste a URL from Fencing Time Live 1) Results, 2) Fencers, 3) Pool Details, etc.
                        The fencer names in the table are clickable to see a summary of that fencer's past results.
                    </div>
                </div>
            </div>
            <div className="mr-2 ml-2 mt-8">
                <div className=" text-xl text-center mb-4 bg-gray-300">Crawled Page for ELOs</div>
                {(err)
                    ? <div className="text-red-500">{err}</div>
                    : <></>
                }                
                {(loading)
                    ? "Loading..."
                    : <Table data={data} columns={columns} paginate={true} />
                }
            </div>
        </div>
    );
}

