import React, { useState } from "react";
import { TableInstance } from "react-table";
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import Dropdown, { Option } from 'react-dropdown';
import 'react-dropdown/style.css';
import Select from "react-select/dist/declarations/src/Select";
import { LINK_CLASS_NAME } from "../CellHelpers";
const leftIcon = (<ChevronLeftIcon style={{ display: "inline", width: 12, height: 12, fill: "black" }} />);
const rightIcon = (<ChevronRightIcon style={{ display: "inline", width: 12, height: 12, fill: "black" }} />);

type PaginationProps = {
	tableInstance: TableInstance<object>;
};

const formatOption = (val: string | number): Option => {
	return { value: val.toString(), label: <span className="">{val}</span> };
}

export const Pagination = ({ tableInstance }: PaginationProps) => {
	const [needSpace, setNeedSpace] = useState(false);

	const {
		state: { pageSize, pageIndex },
		pageCount,
		rows,
		canPreviousPage,
		canNextPage,
		gotoPage,
		setPageSize,
		previousPage,
		nextPage,
	} = tableInstance;

	const itemsPerPageOptions = [5, 10, 15, 20, 25, 50, 100, 1000].map((x: any) => formatOption(x));

	const numPagesOptions = Array(pageCount)
		.fill(0)
		.map((_, index) => formatOption(index + 1));

	const lastRecNum = (pageIndex + 1 < pageCount) ? pageIndex * pageSize + pageSize : rows.length;

	const onCopyTable = () => {
		const origRows = rows.map(row => row.original);
		console.log(JSON.stringify(origRows));
		navigator.clipboard.writeText(JSON.stringify(origRows));
		alert("Table copied to clipboard");
	}

	const onDropdownFocus = () => {
		setNeedSpace(true);
	}

	return (
		<div className={`text-sm mt-4 mb-4 font-light`}>
			<div className="w-full flex items-center gap-3">
				<span>Items per page:{" "}</span>
				<Dropdown
					menuClassName=""
					options={itemsPerPageOptions}
					value={formatOption(pageSize)}
					onChange={(selected) => { setPageSize(parseInt(selected?.value || '0')); setNeedSpace(false); }}
					onFocus={onDropdownFocus}
				/>
				<VerticalSeparator />
				<span>
					{pageIndex * pageSize + 1} - {lastRecNum} of{" "}
					{rows.length}
				</span>
				<VerticalSeparator />
				<Dropdown
					className=""
					options={numPagesOptions}
					value={formatOption(pageIndex + 1)}
					onChange={(selected) => { gotoPage(parseInt(selected.value) - 1); setNeedSpace(false); }}
					onFocus={onDropdownFocus}
				/>
				of {pageCount} pages
				<VerticalSeparator />
				<span
					onClick={previousPage}
					className={`inline h-4 w-4 select-none ${canPreviousPage ? "cursor-pointer text-black" : "text-neutral-30"
						}`}
				>
					{leftIcon}
				</span>
				<span
					onClick={nextPage}
					className={`inline h-4 w-4 select-none ${canNextPage ? "cursor-pointer text-black" : "text-neutral-30"
						}`}
				>
					{rightIcon}
				</span>
				<VerticalSeparator />
				<a className={LINK_CLASS_NAME} onClick={onCopyTable}>Copy Table</a>
			</div>
			<div>
				<span className={`${(needSpace) ? "h-48" : ""}`}></span>
			</div>
		</div >
	);
};

const VerticalSeparator = () => <div className="h-8 w-[1px] bg-neutral-30" />;
