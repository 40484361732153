
import { useState } from 'react';
import { PlayerLookup } from './PlayerLookup';
import { SqlRow } from './ToolCrawl';

interface PlayerDataProps {
    player1: SqlRow[];
    player2: SqlRow[];
}


function PlayerData(props: PlayerDataProps) {   
    const { player1, player2 } = props;

    let keys: string[] = [];

    if (player1 && player1[0]) {
        keys = Object.keys(player1[0]);
    } else if (player2 && player2[0]) {
        keys = Object.keys(player2[0]);
    }

    return (
        <table>
            {keys.map(key => {
                return (
                <tr>
                    <td>{key}</td>
                    <td>{player1?.[0]?.[key]}</td>
                    <td>{player2?.[0]?.[key]}</td>
                </tr>
                )
            })}
        </table>
    );
}


export function PlayerLookupTool() {
    const [player1Data, setPlayer1Data] = useState([] as SqlRow[]);
    const [player2Data, setPlayer2Data] = useState([] as SqlRow[]);


    const onPlayer1Data = (rows: SqlRow[]) => {
        setPlayer1Data(rows);
    }

    const onPlayer2Data = (rows: SqlRow[]) => {
        setPlayer2Data(rows);
    }

    return (
        <div className="text-center">
            <div className="mb-4">Player Lookup:</div>
            <div className="mb-4 flex flex-row justify-center">
                <PlayerLookup onPlayerData={onPlayer1Data}/>
                <PlayerLookup onPlayerData={onPlayer2Data}/>
            </div>
            <PlayerData player1={player1Data} player2={player2Data}/>
        </div>
    );
}
