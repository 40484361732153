import { clubCell, fencerCell } from "./CellHelpers";
import { SqlRow } from "./ToolCrawl";
import { fetchSql } from "./helpers";
import { useEffect, useState } from "react";
import { Table } from "./table";


const fencerSortBy = [{ id: "fencer_before_elo_pools", desc: true }];


const topFencerColumns = [
    { Header: 'Num', accessor: "num" },
    { Header: 'Fencer', accessor: "fencer_name", Cell: fencerCell },
    { Header: 'Rating', accessor: "fencer_old_rating" },
    { Header: 'ELO (DEs)', accessor: "fencer_before_elo" },
    { Header: 'ELO (Pools)', accessor: "fencer_before_elo_pools" },
    { Header: 'Last DE', accessor: "last_elo_date" },
    { Header: 'Club 1', accessor: "fencer_club1", Cell: clubCell },
    { Header: 'Club 2', accessor: "fencer_club2", Cell: clubCell },
];

export function TopFencers() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([] as SqlRow[]);

    useEffect(() => {
        const f = async () => {
            setData([]);
            setLoading(true);

            const promises = [
                (async () => {
                    const postData = { queryName: "top_fencers", queryParams: {} };
                    const { rows, err } = await fetchSql(postData);
                    setData(rows || []);
                })(),
            ];
            await Promise.all(promises);

            setLoading(false);
        }
        f();
    }, []);

    return (
        <div>
            <div className="bg-blue-300 mb-4 pt-2 pb-2 flex flex-col text-center">
                <span className="text-2xl">Top Fencers</span>
            </div>

            {(loading) ? "Loading (slow query, please wait)..." :
                <div className="mr-2 ml-2 mt-4">
                    <div className="text-xl text-center mb-4 bg-gray-300">Fencers</div>
                    <Table data={data} columns={topFencerColumns} paginate={true} sortBy={fencerSortBy}></Table>
                </div>
            }
        </div>

    )
}