import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchSql } from "./helpers";
import { SqlRow } from "./ToolCrawl";
import { Table, createColsFromData } from "./table";
import { directElimsCell, eventResultCell, fencerCell, poolsCell } from "./CellHelpers";
import Select from "react-select";
import { toOpt } from "./Selector";


const IGNORE_COLUMNS = ["num_fencer_scaled", "event_id", "tourney_id", "sort"];


const reportOptions = [
    { value: 'recent_big_events', label: 'Recent Big Events' },
    { value: 'recent_events', label: 'Recent Events (last 1000)', pageSize: 20 },
    { value: 'fencers_most_events', label: 'Fencers with Most # of Events', pageSize: 100 },
    { value: 'overall_stats', label: 'Overall Stats' },
    { value: 'biggest_tableau_upsets', label: 'Biggest DE Upsets', pageSize: 100 },
    { value: 'biggest_pools_upsets', label: 'Biggest Pools Upsets', pageSize: 100 },
    { value: 'tableau_elo_predict_accuracy', label: 'Simulator - DE ELO Accuracy' },
    { value: 'pools_elo_predict_accuracy', label: 'Simulator - Pools ELO Accuracy' },
];


export function Reports() {
    const { reportName } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([] as SqlRow[]);

    useEffect(() => {
        const f = async () => {
            setData([]);
            setLoading(true);

            const postData = { queryName: reportName, queryParams: {} };
            const { rows, err } = await fetchSql(postData);
            setData(rows || []);

            setLoading(false);
        }
        f();
    }, [reportName]);


    const origCols = createColsFromData(data);
    const cols = origCols
        .filter(col => !IGNORE_COLUMNS.includes(col.accessor))
        .map(col => {
            if (col.accessor === "event_name") {
                col.Cell = eventResultCell;
            }
            if (col.accessor === "fencer_name" || col.accessor === "other_name") {
                col.Cell = fencerCell;
            }
            return col;
        });
    if (origCols.find(col => col.accessor === "event_id") && origCols.find(col => col.accessor === "fencer_name")) {
        cols.push({ Header: "Pools", accessor: "Pools", Cell: poolsCell })
        cols.push({ Header: "DEs", accessor: "DEs", Cell: directElimsCell })
    } 

    const selValue = reportOptions.find(opt => opt.value === reportName);

    return (
        <div>
            <div className="bg-blue-300 mb-4 pt-2 pb-2 flex flex-col text-center">
                <span className="text-2xl">Reports</span>
            </div>

            <div className='flex-0 flex flex-wrap justify-center'>
                <div className="w-128 mr-8 mb-4 flex flex-col">
                    <div className="mr-2">
                        Select Report:
                    </div>
                    <Select
                        className="w-96"
                        value={selValue}
                        onChange={(selection) => {
                            navigate(`/reports/${selection?.value!}`)
                        }}
                        options={reportOptions}
                    />
                </div>
            </div>


            {(loading) ? "Loading (slow query, please wait)..." :
                <div className="mr-2 ml-2 mt-4">
                    <div className="text-xl text-center mb-4 bg-gray-300">{selValue?.label}</div>
                    <Table data={data} columns={cols} paginate={!!selValue?.pageSize} initPageSize={selValue?.pageSize}/>
                </div>
            }
        </div>
    )
}