import Select from 'react-select';
import { fixAmp } from "./helpers";


export const toOpt = (ary: any[]) => {
    return (ary || []).map(val => { return { value: val, label: fixAmp(val) } });
}


export interface SelectorProps {
    label: string;
    fieldName: string;
    options: string[];
    value: string;
    selections: any;
    setSelections: any;
}


export const Selector = (props: SelectorProps) => {
    const { label, fieldName, options, selections, setSelections } = props;

    return (
        <div className="w-128 mr-8 flex flex-col">
            <div className="mr-2">
                {label} ({options?.length}):
            </div>
            <Select 
                className="w-96"
                value={selections[fieldName]}
                onChange={(selection) => {
                    const sels = Object.assign({}, selections);
                    sels[fieldName] = selection;
                    setSelections(sels);
                }}
                options={toOpt(options)}
                isSearchable={true}
                isMulti={true}
            />
        </div>
    )
}
