
import { useEffect, useState } from 'react';
import { toOpt } from './Selector';
import { fetchSql } from './helpers';
import Select, { SingleValue } from "react-select";
import { SqlRow } from './ToolCrawl';


export interface OptionType {
    value: string;
    label: string;
}


export interface PlayerLookupProps {
    onPlayerData: (rows: SqlRow[]) => void;
}


export function PlayerLookup(props: PlayerLookupProps) {
    // https://stackoverflow.com/questions/60175452/react-select-and-typescript-type-string-is-not-assignable-to-type-valuetype
    const defaultOptions: OptionType[] = toOpt([]);

    const [inputVal, setInputVal] = useState("");
    const [options, setOptions] = useState(defaultOptions);
    const [finalVal, setFinalVal] = useState("");

    const onChange = (newVal: SingleValue<OptionType | undefined>) => {
        console.log("onChange", newVal);
        setFinalVal(newVal?.value || '');
    }

    const onInputChange = (newVal: any) => {
        console.log("onInputChange", newVal);
        setInputVal(newVal);
    }

    const onSubmit = async () => {
        console.log("onSubmit", finalVal);
        const postData = { queryName: "fencer_recent_info", queryParams: { "$fencer_name": finalVal } };
        const { rows, err } = await fetchSql(postData);
        console.log({ rows, err })
        if (rows) {
            props.onPlayerData(rows);
        }
    }

    useEffect(() => {
        const f = async () => {
            const postData = { queryName: "get_fencer_names", queryParams: { "$prefix": inputVal.toLowerCase() + '%', "$limit": 8 } };
            const { rows, err } = await fetchSql(postData);
            const vals = (rows || []).map(x => x.fencer_name);
            setOptions(toOpt(vals));
        }
        f();
    }, [inputVal]);

    return (
        <div>
            <div className="mb-4 flex flex-row justify-center">
                <div className="w-128">
                    <Select
                        onChange={onChange}
                        onInputChange={onInputChange}
                        value={undefined}  // Danger!  If you set this to a string, magical/weird things happen with the type,and you can a type error on "options" below!
                        options={options}
                    />
                </div>
            </div>
            <div className="mb-4">
                <button className="bg-gray-300 pt-2 pb-2 pr-2 pl-2 mr-2" onClick={onSubmit}>Submit</button>
            </div>
        </div >
    );
}
