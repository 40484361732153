import { useState } from "react";
import { ShowIf } from "./ShowIf";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
const upIcon = (<ChevronUpIcon style={{display: "inline", width: 12, height: 12, fill: "black"}}/>);
const downIcon = (<ChevronDownIcon style={{display: "inline", width: 12, height: 12, fill: "black"}}/>);


export interface ToggleDisplayProps {
    caption: string;
    initialDisplay?: boolean;
    onToggleChange?: (newVisible: boolean) => void;
    children?: any;
    className?: string;
}


export const ToggleDisplay = (props: ToggleDisplayProps) => {
    const { caption, initialDisplay, onToggleChange, className } = props;
    const [visible, setVisible] = useState(!!initialDisplay);

    const onClick = () => {
        const newVisible = !visible;
        setVisible(newVisible);
        if (onToggleChange) {
            onToggleChange(newVisible);
        }
    };

    return (
        <div className={className}>
            <div className="cursor-pointer select-none text-center" onClick={onClick}>{caption} {(visible) ? upIcon: downIcon }</div>
            <ShowIf show={visible}>
                {props.children}
            </ShowIf>
        </div>
    )
};
