import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Test } from "./components/Test";
import './index.css';
import { AllTournaments } from "./components/AllTournaments";
import { TopFencers } from "./components/TopFencers";
import { Club } from "./components/Club";
import { EventResults } from "./components/EventResults";
import { Tools } from "./components/Tools";
import App from "./components/App";
import { Fencer } from "./components/Fencer";
import { Help } from "./components/Help";
import { Reports } from "./components/Reports";
import { Simulator } from "./components/Simulator";

const rootElement = document.getElementById("root");
render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />}>
                <Route index element={<AllTournaments />} />
                <Route path="/top_fencers" element={<TopFencers />} />
                <Route path="/fencer/:fencerName" element={<Fencer />} />
                <Route path="/club/:clubName" element={<Club />} />
                <Route path="/event_results/:eventId" element={<EventResults />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/reports/:reportName" element={<Reports />} />
                <Route path="/tools" element={<Tools />} />
                <Route path="/simulator" element={<Simulator />} />
                <Route path="/help" element={<Help />} />
                <Route path="/test" element={<Test />} />
                <Route
                    path="*"
                    element={
                        <main>
                            <b>Invalid path</b>
                        </main>
                    }
                />
            </Route>
        </Routes>
    </BrowserRouter>,
    rootElement
);

// Per MobX docs, this is a safety check to make sure things are configured properly
if (!new class { x: any }().hasOwnProperty('x')) throw new Error('Transpiler is not configured correctly');
