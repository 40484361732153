
import { useEffect, useState } from "react";
import { TrophyIcon } from '@heroicons/react/24/solid';
import { DataRow, RenderCellFunc, SimpleTable } from "./SimpleTable";
import { StyleNavLink } from "./StyleNavLink";
import { rowStyleFunc } from "./Pools";
import { fetchSql } from "./helpers";
import { LINK_CLASS_NAME } from "./CellHelpers";
import { SqlRow } from "./ToolCrawl";


export const downIcon = (<TrophyIcon style={{display: "inline", width: 12, height: 12, fill: "blue"}}/>);

const renderDirectElimlCell: RenderCellFunc = (rowIdx: number, colIdx: number, row: DataRow, field: string) => {
    if (field === "other_name") {
        return <>({row.other_seed}) <StyleNavLink to={"/fencer/" + row["other_name"]}>{row.other_name}</StyleNavLink></>
    } else {
        return <>{row[field]}</>;
    }
}


const directElimColumns = [
    { field: "tableau_table" },
    { field: "fencerBoth" },
    { field: "wonStr" },
    { field: "score" },
    { field: "other_name" },
];


export interface DirectElimsProps {
    eventId: string;
    fencerName: string;
}

export const DirectElims = (props: DirectElimsProps) => {
    const { eventId, fencerName } = props;
    const [show, setShow] = useState(false);
    const [startedLoad, setStartedLoad] = useState(false);
    const [finishedLoading, setSetFinishedLoading] = useState(false);
    const [results, setResults] = useState([] as SqlRow[]);

    useEffect(() => {
        const f = async () => {
            if (startedLoad) {
                const postData = { queryName: "fencer_event_tableau_matches", queryParams: { "$event_id": eventId, "$fencer_name": fencerName } };
                const { rows, err } = await fetchSql(postData);
                const newRows = (rows || []).map((row: any) => { 
                    return {...row, 
                        wonStr: (row.won) ? "Won" : "Lost", 
                        fixOrder: row.fencer_order + 1,
                        fencerBoth: `(${row.fencer_seed}) ${row.fencer_name}`,
                    }});
                setResults(newRows);
                setSetFinishedLoading(true);
            }
        }
        f();
    }, [eventId, fencerName, startedLoad]);

    const toggleShow = () => {
        setShow(!show);
        if (!startedLoad) {
            setStartedLoad(true);
        }
    }

    const numWon = results.map((row: any) => row.won).reduce((partial, a) => partial + a, 0);
    const numLost = results.map((row: any) => 1-row.won).reduce((partial, a) => partial + a, 0);

    return (
        <>
            <div style={{ cursor: "pointer" }}>
                <a className={LINK_CLASS_NAME} onClick={toggleShow}>{(!show) ? <>{downIcon} DEs</> : <>{downIcon} Hide</>}</a>
            </div>
            {(show && startedLoad && finishedLoading) ? (
                <div style={{backgroundColor: "#EEE"}} >
                    <div>
                        <h4 style={{textAlign: "center"}}>Won {numWon} - Lost {numLost}</h4>
                    </div>
                    <SimpleTable columns={directElimColumns} data={results} renderCell={renderDirectElimlCell} rowStyleFunc={rowStyleFunc}/>
                </div>
            ) : (
                <></>
            )}
            {(show && startedLoad && !finishedLoading) ? (
                <div>
                    Loading...
                </div>
            ) : (
                <></>
            )}
        </>)
}


