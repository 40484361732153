
import { ChangeEventHandler, MouseEventHandler, useState } from "react";
import { WatchEventData } from "./WatchEventData";


export type SqlRow = Record<string, string | number>;


export interface CrawlUrlForElosResult {
    rows?: SqlRow[];
    fencerFields?: {[key:string]: string};
    errMessage?: string;
}


export function WatchEvent() {
    const defaultUrl = "https://www.fencingtimelive.com/pools/scores/B50EB96DCAFD4D44BC5A3EE1706E2798/DE4549CDA0264990AC3AE7D5F3DD3879";

    const [crawlUrl, setCrawlUrl] = useState(defaultUrl);
    const [submitTime, setSubmitTime] = useState(0);

    const onInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setSubmitTime(0);
        setCrawlUrl(event.target.value);
    }

    const onButtonClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
        setSubmitTime(Date.now());
    }

    const onClearClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
        setCrawlUrl('');
        setSubmitTime(0);
    }

    return (
        <div>
            <div>
                <div className="text-center">
                    <div className="mb-4">Crawl URL:</div>
                    <div className="mb-4">
                        <span className="border-black border-2 pb-1 pt-1 pl-1 pr-1">
                            <input className="w-96 border-none" type="text" id="crawl_url" value={crawlUrl} onChange={onInputChange} />
                        </span>
                    </div>
                    <div className="mb-4">
                        <button className="bg-gray-300 pt-2 pb-2 pr-2 pl-2 mr-2" onClick={onButtonClick}>Submit</button>
                        <button className="bg-gray-300 pt-2 pb-2 pr-2 pl-2 ml-2" onClick={onClearClick}>Clear</button>
                    </div>
                    <div className="mt-4 mb-4 text-sm">
                        Paste an Event Url above (into the Crawl URL input).  This URL will be automatically crawled & updated.
                    </div>
                </div>
            </div>
            {(submitTime && crawlUrl) 
                ? <WatchEventData crawlUrl={crawlUrl} submitTime={submitTime}/>
                : <></>
            }
        </div>
    );
}

