import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchSql, fixAmp } from "./helpers";
import { LINK_CLASS_NAME, clubCell, directElimsCell, fencerCell, newRatingCell, poolsCell } from "./CellHelpers";
import { StyleNavLink } from "./StyleNavLink";
import { SqlRow } from "./ToolCrawl";
import { Table } from "./table";
import { ShowIf } from "./ShowIf";
import { uiState } from "../stores/ui_store";


const placeSortBy = [{ id: "place_num", desc: false }];

const RATINGS = ["A", "B", "C", "D", "E", "U"];


const eventResultColumns = [
    { Header: 'Place', accessor: "place_num" },
    { Header: 'Fencer', accessor: "fencer_name", Cell: fencerCell  },
    { Header: 'DE ELO', accessor: "tableau_previous_elo" },
    { Header: 'Pools ELO', accessor: "pools_previous_elo" },
    { Header: 'Pools', accessor: "pools", Cell: poolsCell },
    { Header: 'DEs', accessor: "des", Cell: directElimsCell },
    { Header: 'Rating', accessor: "old_rating" },
    { Header: 'New Rating', accessor: "new_rating", Cell: newRatingCell },
    { Header: 'Club 1', accessor: "club1", Cell: clubCell },
    // { Header: 'Club 2', accessor: "club2", Cell: clubCell },
];


export function EventResults() {
    const navigate = useNavigate();
    const { eventId } = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([] as SqlRow[]);

    useEffect(() => {
        const f = async () => {
            setLoading(true);

            const postData = { queryName: "event_results", queryParams: { $event_id: eventId } };
            const { rows, err } = await fetchSql(postData);

            setData(rows || []);
            setLoading(false);
        }
        f();
    }, []);

    const tourneyName = fixAmp(data?.[0]?.tourney_name);
    const tourneyDate = data?.[0]?.tourney_date;
    const tourneyLoc = data?.[0]?.tourney_location;
    const fixEventName = fixAmp(data?.[0]?.event_name);
    const numFencer = data?.[0]?.num_fencer;

    const eventIdPrefix = eventId?.split("_")?.[0];
    const rawEventId = eventId?.split("_")?.[1];

    const getExternalLink = () => {
        if (eventIdPrefix === "ftl") {
            const link = `https://fencingtimelive.com/events/results/${rawEventId}`;
            return { link, name: "On FencingTimeLive.com" };
        } else if (eventIdPrefix === "af") {
            const link = `https://askfred.net/Results/roundResults.php?seq=1&event_id=${rawEventId}`;
            return { link, name: "On AskFred.net" };
        } else {
            return {};
        }
    };
    const { link, name } = getExternalLink();

    const simLink = `/simulator/?eventId=${eventId}`;

    const ratingCounts = {} as {[key:string]:number};
    for (const row of data || []) {
        for (const rating of RATINGS) {
            if (row["old_rating"]?.toString().includes(rating)) {
                ratingCounts[rating] = (ratingCounts[rating] || 0) + 1;
            }
        }
    }

    const ratingCountAry = [];
    for (const rating of RATINGS) {
        if (ratingCounts[rating]) {
            ratingCountAry.push(<span className="mr-4">{rating}: {ratingCounts[rating]}</span>);
        }
    }

    const onSimClick = () => {
        uiState.setSimAutoRun(true);
        navigate(simLink);
    };
    
    return (
        <div>
            <div className="bg-blue-200 mt-4 mb-4 pt-2 pb-2 flex flex-col text-center">
                <span className="text-2xl">{tourneyName}</span>
                <div>
                    <span className="mr-2">{tourneyDate}</span>
                    <span className="ml-2">{tourneyLoc}</span>
                </div>
            </div>

            <div className="bg-green-200 mb-4 pt-2 pb-2 flex flex-col text-center">
                <span className="text-2xl">{fixEventName}</span>
                <div>
                    <span className="mr-8">{numFencer} Fencers</span>
                    <StyleNavLink className="mr-8" target="_blank" to={link}>{name}</StyleNavLink>
                    <ShowIf show={!!simLink}>
                        <a className={LINK_CLASS_NAME} onClick={onSimClick}>Simulator</a>
                    </ShowIf>
                </div>
                <div className="mt-2 text-sm">{ratingCountAry}</div>
                <div className="mt-2 text-sm">Note that ELOs are from immediately before the event (not what they are now)</div>
            </div>

            {(loading) ? "Loading (slow query, please wait)..." :
            <div className="mr-2 ml-2 mt-4">
                <div className="text-xl text-center mb-4 bg-gray-300">Fencers</div>
                <Table data={data} columns={eventResultColumns} paginate={false} sortBy={placeSortBy}></Table>
            </div>
            }
        </div>
    );
}
