import { ChangeEventHandler } from 'react';
import { useSearchParams } from "react-router-dom";
import Select from 'react-select';
import { SimProps } from './sim_types';
import { estWinOptions, uiState, useEloOptions } from '../../stores/ui_store';
import { observer } from "mobx-react-lite"
import { ToggleDisplay } from '../ToggleDisplay';


export interface SimInputTabProps {
    onRunSim: (simProps: SimProps) => void;
}


export const SimInputTab = observer((props: SimInputTabProps) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const onCrawlUrlChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        searchParams.set("crawlUrl", event.target.value);
        setSearchParams(searchParams);
    }

    const onEventIdChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        searchParams.set("eventId", event.target.value);
        setSearchParams(searchParams);
    }

    const onNumIterChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        uiState.setSimNumIter(event.target.value);
    }

    const onTextAreaChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        uiState.setSimRawText(event.target.value);
    }

    const onRunSim = () => {
        uiState.setSimAutoShowResults(true);
        props.onRunSim({
            eventId: searchParams.get("eventId") || '',
            crawlUrl: searchParams.get("crawlUrl") || '',
            numIter: parseInt(uiState.simNumIter),
            estWinStrategy: uiState.simEstWinSel.value,
            useEloMethod: uiState.simUseEloSel.value,
            crawlRawText: uiState.simRawText,
        });
    };

    if (uiState.simAutoRun) {
        uiState.setSimAutoRun(false);
        onRunSim();
    }

    return (
        <>
            <div className='flex-0 flex flex-wrap justify-center'>
                <div className="mr-4 mb-4">
                    <div className="mb-2">Event ID:</div>
                    <div className="">
                        <span className="border-black border-2 pb-1 pt-1 pl-1 pr-1">
                            <input className="w-96 border-none" type="text" id="crawl_url" value={searchParams.get("eventId") || ''} onChange={onEventIdChange} />
                        </span>
                    </div>
                </div>
                <div className="mr-4 mb-4">
                    <div className="mb-2">Paste link to Fencing Time Live event's Seeding:</div>
                    <div className="">
                        <span className="border-black border-2 pb-1 pt-1 pl-1 pr-1">
                            <input className="w-96 border-none" type="text" id="crawl_url" value={searchParams.get("crawlUrl") || ''} onChange={onCrawlUrlChange} />
                        </span>
                    </div>
                </div>
                <div className="mr-4 mb-4">
                    <div className="mb-2">Number of Iterations:  (&lt;= 25,000)</div>
                    <div className="">
                        <span className="border-black border-2 pb-1 pt-1 pl-1 pr-1">
                            <input className="w-96 border-none" type="text" id="crawl_url" value={uiState.simNumIter} onChange={onNumIterChange} />
                        </span>
                    </div>
                </div>
                <div className="w-128 mr-8 mb-4 flex flex-col">
                    <div className="mr-2">
                        Estimate Wins Strategy:
                    </div>
                    <Select
                        className="w-96"
                        value={uiState.simEstWinSel}
                        onChange={(selection) => {
                            uiState.setSimEstWinSel(selection);
                        }}
                        options={estWinOptions}
                    />
                </div>
                <div className="w-128 mr-8 mb-4 flex flex-col">
                    <div className="mr-2">
                        ELOs to use:
                    </div>
                    <Select
                        className="w-96"
                        value={uiState.simUseEloSel}
                        onChange={(selection) => {
                            uiState.setSimUseEloSel(selection);
                        }}
                        options={useEloOptions}
                    />
                </div>
                <div className="w-128 mr-8 mb-4 flex flex-col">
                    <ToggleDisplay className="mr-2" caption="Fencer Names">
                        <div className="w-96">Enter the Fencer names below, one per line, using format "LASTNAME FirstName"</div>
                        <textarea className="border-black border-2 pb-1 pt-1 pl-1 pr-1" id="raw_text" name="raw_text" rows={10} cols={50} value={uiState.simRawText} onChange={onTextAreaChange}/>
                    </ToggleDisplay>
                </div>
            </div>
            <div className="mt-4 flex-0 flex flex-wrap justify-center">
                <input onClick={onRunSim} defaultValue="Run Simulation" className="bg-cyan-300 pt-2 pb-2 pr-2 pl-2 cursor-pointer" type="Submit" />
            </div>
            <div className="mr-16 ml-16 pt-4 pb-4 pr-4 pl-4 mt-8 flex-0 justify-center bg-yellow-100">
                <p className="mb-4 text-xl">Simulator Notes:</p>
                <ol>
                    <li className="mb-4">
                        The simulator is just a tool that "simulates" who can win a tournament.  It uses the player's ELO to see what chance they
                        have to beat another player.
                    </li>
                    <li className="mb-4">
                        It works by simulating the event a large number of times (e.g., &gt; 1000), and collecting stats for each player.  Currently the stats
                        it collects is the player's # of finishes in each table (e.g. Table 1, Table 2, etc).
                    </li>
                    <li className="mb-4">
                        The simulator predicts who will win the event based on which player has the highest # of finishes in Table 1.
                    </li>
                    <li className="mb-4">
                        You can click on a player in the "Output" table to see their best bracket (where they placed the highest).
                    </li>
                    <li className="mb-4">
                        <div className="mb-4">The simulator can be used in two ways:</div>
                        <ol>
                            <li className="ml-8 mb-4">
                                If you click the "Simulator" link on an event on this site, it will automatically populate the "eventId" field,
                                and you just need to pick your parameters and then press "Run Simulation".
                            </li>
                            <li className="ml-8 mb-4">
                                For an event in progress that hasn't been loaded yet, you can paste the URL from "Fencing Time Live"
                                for the "Pools"/"Seeding" link.  Copy and paste that into the "Paste Link to Fencing Time Live..." input box.
                                Choose your other parameters and press "Run Simulation".
                            </li>
                        </ol>
                    </li>
                    <li className="mb-4">
                        The simulator is currently in <b>Beta</b> (under construction).
                        It currently simulates Pools and the Direct Elimination round.  It uses either the pools seed (if using a crawlUrl) or 
                        final placement (if clicking from an event) of a player to seed the pools.
                    </li>
                </ol>
            </div>
        </>
    )
});
