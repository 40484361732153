import { createContext, useEffect, useState } from "react";

export type SizeContainerContextType = {
    width: number | undefined;
    height: number | undefined;
    outdated: boolean;
};

export const SizeContext = createContext({} as SizeContainerContextType);

type SizeContainerProps = {
    children: React.ReactNode;
    className?: string;
};

/**
 * 
    const dimensions = useContext(SizeContext);
 * 
 * @param param0 
 * @returns 
 */
export const SizeContainer = ({ children, className }: SizeContainerProps) => {
    const [dimensions, setDimensions] = useState({
        width: undefined as number | undefined,
        height: undefined as number | undefined,
        outdated: true, // Triggers a re render when changed
    } as SizeContainerContextType);

    const parentRefCallback = (element: HTMLDivElement) => {
        if (element) {
            const width = element.clientWidth;
            const height = element.clientHeight;
            if (dimensions.width !== width || dimensions.height !== height) {
                setDimensions({ width, height, outdated: false });
            }
        }
    };

    useEffect(() => {
        const handler = () => {
            setDimensions({ ...dimensions, outdated: true }); // re renders!
        };
        window.addEventListener("resize", handler);
        return () => window.removeEventListener("resize", handler);
    }, [dimensions]);

    return (
        <SizeContext.Provider value={dimensions}>
            <div ref={parentRefCallback} className={(className) ? className : "w-full"}>
                {children}
            </div>
        </SizeContext.Provider>
    );
};

