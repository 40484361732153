import { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { SimInputTab } from './SimInputTab';
import { SimRunTab } from './SimRunTab';
import { PlayerSimStats, SimProps, SimulationResults } from './sim_types';
import { CellFunc, Table, createColsFromData } from '../table';
import { v4 as uuidv4 } from 'uuid';
import { Row } from 'react-table';
import { LINK_CLASS_NAME } from '../CellHelpers';
import { fixAmp } from '../helpers';
import { SimStepper } from './SimStepper';
import { uiState } from "../../stores/ui_store";


const table1SortBy = [{ id: "avgTableau", desc: false }];

export function Simulator() {
    const [tabIndex, setTabIndex] = useState(0);
    const [simProps, setSimProps] = useState(undefined as SimProps | undefined);
    const [jobId, setJobId] = useState(undefined as string | undefined);
    const [results, setResults] = useState(undefined as SimulationResults | undefined);
    const [resultRows, setResultRows] = useState([] as any[]);
    const [overallWinner, setOverallWinner] = useState(undefined as string | undefined)

    const onSelectTab = (index: number, _lastIndex: number, _event: Event): boolean => {
        setTabIndex(index);
        return true;
    };


    const getTabPanelClassName = (baseIdx: number): string => {
        return `react-tabs__tab-panel  ${(baseIdx === tabIndex) ? "flex-1" : "flex-0"} flex flex-col`;
    }


    const onRunSim = (simProps: SimProps) => {
        setSimProps(simProps);
        setJobId(uuidv4());
        setTabIndex(1);
    };


    const onShowResults = (results: SimulationResults) => {
        onComplete(results);
        setTabIndex(2);
    };


    const onComplete = (results: SimulationResults) => {
        const fixResults = results.playerStats.map((row: PlayerSimStats) => {
            const avgTableau = parseFloat((row.totTableauPlace / row.numFinishes).toFixed(1));
            const avgPools = parseFloat((row.totPoolsPlace / row.numFinishes).toFixed(1));
            const newRow = { ...row.playerSeed, numFinishes: row.numFinishes, ...row.tableFinishes, avgTableau, avgPools };
            return newRow;
        });
        setResults(results);
        setResultRows(fixResults);
    };


    const overallWinnerCell: CellFunc = ({ value, row }: { value: string | number, row: Row }): JSX.Element => {
        const onClick = () => {
            setTabIndex(3);
            setOverallWinner(value.toString());
            uiState.setSimStepperJobId(undefined);  // This resets it, to force user to click button
        };
        return (<a className={LINK_CLASS_NAME} onClick={onClick}>{fixAmp(value?.toString())}</a>);
    };

    const cols = createColsFromData(resultRows).filter(col => col.accessor !== "bracket");
    const foundCol = cols.find((row: any) => row.accessor === "player");
    if (foundCol) {
        foundCol.Cell = overallWinnerCell;
    }    

    return (
        <div className="w-full flex-1 flex flex-col">
            <Tabs className="react-tabs  flex-1 flex flex-col" selectedIndex={tabIndex} onSelect={onSelectTab}>
                <TabList className="react-tabs__tab-list  flex-0">
                    <Tab>Input</Tab>
                    <Tab>Running</Tab>
                    <Tab>Output</Tab>
                    <Tab>Stepper</Tab>
                </TabList>

                <TabPanel className={getTabPanelClassName(0)}>
                    <SimInputTab onRunSim={onRunSim}/>
                </TabPanel>

                <TabPanel className={getTabPanelClassName(1)}>
                    <SimRunTab jobId={jobId} simProps={simProps} onShowResults={onShowResults} onComplete={onComplete}/>
                </TabPanel>

                <TabPanel className={getTabPanelClassName(2)}>
                    <div className="text-center mb-4 bg-gray-300">
                    <div className="text-xl mb-2">Number of Simulated Finishes in each Table</div>
                    <div className="text-sm text-center mb-1">Click a player to see their best bracket</div>
                    <div className="text-sm text-center mb-1">Select the "Input" tab to learn about the Simulator, and run it with different options</div>
                    </div>
                    <Table data={resultRows} columns={cols} sortBy={table1SortBy} initPageSize={1000}/>
                </TabPanel>

                <TabPanel className={getTabPanelClassName(3)}>
                    <SimStepper overallWinner={overallWinner} simProps={simProps}/>
                </TabPanel>
            </Tabs>
        </div>
    )
}
