import { OnePool } from "./sim_types";



export interface SimShowPoolProps {
    poolNum: number;
    pool: OnePool;
    overallWinner?: string;
}

function TabRow(props: any) {
    const className = props.className || '';

    return (
        <tr className={`pt-2 pb-2 ${className}`}>
            {props.children}
        </tr>
    )
}

function TabCell(props: any) {
    const className = props.className || '';

    return (
        <td className={`pl-2 pr-2 ${className}`}>
            {props.children}
        </td>
    )
}

export function SimShowPool(props: SimShowPoolProps) {
    const { pool, poolNum, overallWinner } = props;

    const players = pool.players;
    const bouts = pool.bouts;

    return (
        <div className="mb-8 ml-8 mr-8">
            <div className="bg-blue-200 pl-2 pr-2 mb-2">
                Pool #{poolNum}
            </div>
            <table className="">
                <thead>
                    <TabRow className="bg-gray-200">
                        <TabCell>
                            Num
                        </TabCell>
                        <TabCell>
                            Player
                        </TabCell>
                        {players.map((player, idx) => {
                            return (<TabCell>
                                {idx + 1}
                            </TabCell>)
                        })}
                    </TabRow>
                </thead>
                <tbody>
                    {players.map((player, row) => {
                        const p1 = player.player;
                        const playerColor = (player.player === overallWinner) ? "bg-purple-300" : "";
                        return (
                            <TabRow key={row}>
                                <TabCell key={-1}>{row+1}</TabCell>
                                <TabCell key={-2} className={playerColor}>
                                    {player.player}
                                </TabCell>
                                {players.map((player, col) => {
                                    const p2 = player.player;
                                    const bout = bouts.find(bout => (bout.player1?.player === p1 && bout.player2?.player === p2) ||
                                        (bout.player1?.player === p2 && bout.player2?.player === p1));
                                    const flip = bout?.player1?.player !== p1;  // Bout has a player1 and player2, but the may be crossed, we need to account for that
                                    const won = bout?.winner?.player === p1;
                                    const p1Score = (!flip) ? bout?.score?.player1Score : bout?.score?.player2Score;

                                    let cellContents = col.toString();
                                    if (row === col) {
                                        cellContents = '';
                                    } else {
                                        cellContents = (won) ? "V" + p1Score : "D" + p1Score;
                                    }
                                    let bgClass = '';
                                    if (col === row) {
                                        bgClass = "bg-gray-800";
                                    } else {
                                        bgClass = (won) ? "bg-green-300" : "bg-red-300";
                                    }
                                    return (
                                        <TabCell className={bgClass} key={col}>
                                            {cellContents}
                                        </TabCell>
                                    )
                                })}
                            </TabRow>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
