

export interface ButtonProps {
    caption: string;
    className?: string;
    enabled?: boolean;
    onClick: () => void;
}

export const Button = (props: ButtonProps) => {
    const { caption, className, enabled, onClick } = props;

    const enabledProps = (enabled) ? "bg-cyan-300 cursor-pointer" : "bg-gray-300";
    
    return (
        <input onClick={onClick} defaultValue={caption} className={`${enabledProps} pt-2 pb-2 pr-2 pl-2 ${className}`} type="Submit" />
    )
};
