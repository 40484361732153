import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ToolCrawl } from "./ToolCrawl";
import { uiState } from "../stores/ui_store";
import { observer } from "mobx-react-lite"
import { WatchEvent } from "./WatchEvent";
import { PlayerLookup } from "./PlayerLookup";
import { PlayerLookupTool } from "./PlayerLookupTool";


export const Tools = observer(() => {

    const onSelectTab = (index: number, _lastIndex: number, _event: Event): boolean => {
        uiState.setToolsActiveTab(index);
        return true;
    };


    return (
        <div className="flex-1 flex flex-col">
            <div className="">
                <div className="text-xl text-center mb-4 bg-gray-300">Tools</div>
            </div>
            <Tabs className="react-tabs flex-1 flex flex-col" selectedIndex={uiState.toolsActiveTab} onSelect={onSelectTab}>
                <TabList className="react-tabs__tab-list flex-0">
                    <Tab>Crawl Page for ELOs</Tab>
                    <Tab>Watch Event for Updates</Tab>
                    <Tab>Player Lookup</Tab>
                </TabList>

                <TabPanel>
                    <ToolCrawl />
                </TabPanel>
                <TabPanel>
                    <WatchEvent />
                </TabPanel>
                <TabPanel>
                    <PlayerLookupTool />
                </TabPanel>
            </Tabs>
        </div>
    );
});
