import { Table } from "./table";


const data = Array(25).fill(0).map((x, index) => {
    return { name: `name ${index}`, country: 'USA', zip: index * 1000 };
});


const columns = [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Country', accessor: 'country' },
    { Header: 'Zip', accessor: 'zip' },
];

export function Test() {
    return (
        <div>
            <div className="flex flex-col">
                <span className="w-full bg-orange-300">Test</span>
                <span className="mt-8">Line 1</span>
                <span className="mt-8">Line 2</span>
                <span>Line 3</span>
            </div>
            <Table data={data} columns={columns} paginate={false} />
        </div>
    );
}

