import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { SimProps, SimulationResults } from "./sim_types";
import { useMemo, useState } from "react";
import { SimShowBracket } from "./SimShowBracket";
import { useRemoteJobRunner } from "./remote_job_runner";
import { v4 as uuidv4 } from 'uuid';
import { ShowIf } from "../ShowIf";
import { SimShowPool } from "./SimShowPool";
import { runUntilOptions, uiState } from "../../stores/ui_store";
import { observer } from "mobx-react-lite";
import { Button } from "../Button";
import { CellFunc, Table } from "../table";
import { fencerLink } from "../CellHelpers";
import { SizeContainer } from "../SizeContainer";
import { VirtualList } from "../VirualList";
import { ToggleDisplay } from "../ToggleDisplay";
import Select from "react-select";
import { Checkbox } from "../Checkbox";


const seedColumns = [
    { Header: 'Seed', accessor: "seed" },
    { Header: 'Fencer', accessor: "player" },
    { Header: 'DEs ELO', accessor: "deElo" },
    { Header: 'Pools ELO', accessor: "poolsElo" },
];


const poolsResultsColumns = [
    { Header: 'Place', accessor: "rank" },
    { Header: 'Fencer', accessor: "player" },
    { Header: 'V', accessor: "V" },
    { Header: 'M', accessor: "M" },
    { Header: 'V/M', accessor: "v_over_m" },
    { Header: 'TS', accessor: "TS" },
    { Header: 'TR', accessor: "TR" },
    { Header: 'ind', accessor: "ind" },
];


const tableauResultsColumns = [
    { Header: 'Place', accessor: "place" },
    { Header: 'Fencer', accessor: "player" },
];


export interface SimStepperProps {
    simProps?: SimProps;
    overallWinner?: string;
}

export const SimStepper = observer((props: SimStepperProps) => {
    const { simProps, overallWinner } = props;
    const [tabIndex, setTabIndex] = useState(0);

    const highlightWinnerCell: CellFunc = ({ value }: { value: string | number }): JSX.Element => {
        const isHighlight = value === overallWinner;
        const highlightCol = (isHighlight) ? "bg-purple-300" : "";
        return <span className={highlightCol}>{value}</span>;
    };

    const onSelectTab = (index: number, _lastIndex: number, _event: Event): boolean => {
        setTabIndex(index);
        return true;
    };

    const onSimStep = () => {
        const newJobId = uuidv4();
        uiState.setSimStepperJobId(newJobId);
        // console.log("onSimStep", newJobId, simProps);
    };

    const newSimProps: SimProps = useMemo(() => {
        return Object.assign({}, simProps, { 
            numIter: (uiState.simStepRunUntil.value === runUntilOptions[0].value) ? "1" : "1000", 
            runUntilMethod: uiState.simStepRunUntil.value,
            highlightFencer: overallWinner
        });
    }, [uiState.simStepperJobId]);  // simProps, uiState.simStepRunUntil, overallWinner - Nope!  we only run the query if jobId changed (it's how we know user clicked the run button)
    const { loading, apiStatus, apiResult, apiError } = useRemoteJobRunner<SimulationResults>(uiState.simStepperJobId, "simulator", JSON.stringify(newSimProps));

    const seedRows = (apiResult?.returnEvent?.poolInfo?.seeds || []).map(row => {
        return Object.assign({}, row, { eventId: simProps?.eventId });
    });
    (seedColumns[1] as any).Cell = highlightWinnerCell;

    const poolResultRows = (apiResult?.returnEvent?.poolResults?.results || []).map((row, idx) => {
        return { ...row, player: row.player.player, rank: idx + 1, "v_over_m": row["index1"].toFixed(2) };
    });
    (poolsResultsColumns[1] as any).Cell = highlightWinnerCell;

    const tableauResultsRows = (apiResult?.returnEvent?.tableauResults.results || []).map((row, idx) => {
        return { ...row, player: row.player.player };
    });
    (tableauResultsColumns[1] as any).Cell = highlightWinnerCell;

    const tableauPlace = apiResult?.highlightFencerTableauPlace;
    const poolsPlace = apiResult?.highlightFencerPoolsPlace;

    const onAutoScrollClick = (newChecked: boolean) => {
        uiState.setSimStepAutoScroll(newChecked);
    };


    return (
        <div>
            <div className="text-xl text-center mb-4 bg-gray-300">
                <div>Sim Stepper - {fencerLink(overallWinner) || "Select a fencer in the Output Tab"}</div>
            </div>

            <div className=''>
                <div className="mt-4 flex flex-wrap justify-center">
                    <Button caption="Run Simulation Step" enabled={!loading} onClick={onSimStep} />
                </div>
            </div>
            <div className='mt-4 flex flex-wrap justify-center'>
                <ToggleDisplay caption="Options" className="flex flex-col justify-center">
                    <div className="mt-1 border-2 pt-4 pb-4 pl-8 pr-8 flex flex-wrap justify-center">
                        <Checkbox checked={uiState.simStepAutoScroll} caption="Auto Scroll to Fencer" className="mr-8" onClick={onAutoScrollClick}/>
                        <div className="w-128 mr-8 mb-4 flex flex-col">
                            <div className="mr-2">
                                Run Step Simulation Until:
                            </div>
                            <Select
                                className="w-96"
                                value={uiState.simStepRunUntil}
                                onChange={(selection) => {
                                    uiState.setSimStepRunUntil(selection);
                                }}
                                options={runUntilOptions}
                            />
                        </div>
                    </div>
                </ToggleDisplay>
            </div>

            <ShowIf show={!!apiResult}>
                <div className="mt-4 mb-4 flex flex-wrap justify-center">
                    <span className="mr-8">Pools Place <b>{poolsPlace}</b></span>
                    <span>Tableau Place <b>{tableauPlace}</b></span>
                </div>
            </ShowIf>

            <div>
                <ShowIf show={!!apiError}>
                    <div className="flex-0 bg-red-500">Error {apiError}</div>
                </ShowIf>
                <ShowIf show={!apiResult && apiStatus && apiStatus?.length > 0}>
                    <SizeContainer className="mt-8 flex-1 overflow-hidden flex flex-col">
                        <VirtualList items={apiStatus || []} />
                    </SizeContainer>
                </ShowIf>
            </div>

            <ShowIf show={!!apiResult}>
                <Tabs selectedIndex={tabIndex} onSelect={onSelectTab}>
                    <TabList>
                        <Tab>Seeding</Tab>
                        <Tab>Pools</Tab>
                        <Tab>Pool Results</Tab>
                        <Tab>Tableau</Tab>
                        <Tab>Tableau Results</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="text-xl text-center mb-4 bg-gray-300">Seeding</div>
                        <Table data={seedRows} columns={seedColumns} initPageSize={1000} paginate={false} />
                    </TabPanel>

                    <TabPanel>
                        <div className="text-xl text-center mb-4 bg-gray-300">Pools</div>
                        <div className="mt-4 flex flex-wrap justify-normal">
                            {(apiResult?.returnEvent?.poolInfo?.pools || []).map((pool, idx) => {
                                return <SimShowPool poolNum={idx + 1} pool={pool} overallWinner={overallWinner} />
                            })}
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="text-xl text-center mb-4 bg-gray-300">Pool Results</div>
                        <Table data={poolResultRows} columns={poolsResultsColumns} initPageSize={1000} paginate={false} />
                    </TabPanel>

                    <TabPanel>
                        <div className="text-xl text-center mb-4 bg-gray-300">Bracket</div>
                        <SimShowBracket 
                            bracket={apiResult?.returnEvent?.bracket} 
                            overallWinner={overallWinner} 
                            autoScrollToHighlight={uiState.simStepAutoScroll}
                        />
                    </TabPanel>

                    <TabPanel>
                        <div className="text-xl text-center mb-4 bg-gray-300">Tableau Results</div>
                        <Table data={tableauResultsRows} columns={tableauResultsColumns} initPageSize={1000} paginate={false} />
                    </TabPanel>
                </Tabs>
            </ShowIf>
        </div>
    )
});
