import { format } from "date-fns";
import { SqlRow } from "./ToolCrawl";


const KNOWN_CATEGORIES = ['Y8', 'Y-8', 'Y10', 'Y-10', 'Y12', 'Y-12', 'Y14', 'Y-14', 'Cadet', 'Junior', 
    'Vet-40', 'Vet-50', 'Vet-60', 'Vet-70', 'Vet-80',
    'Veteran', 'Senior'];
const KNOWN_GENDERS = ['Men', 'Women'];


export interface FencerInfo {
    category: string;
    gender: string;
}


export function getFencerInfo(eventResults: SqlRow[]): FencerInfo {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    let lastSep;
    if (month >= 9) {
        lastSep = new Date(year, 9, 1);
    } else {
        lastSep = new Date(year-1, 9, 1);
    }
    const lastDateFmt = format(lastSep, "yyyy/MM/dd");
    // console.log("lastDateFmt", lastDateFmt);

    let category = 'Unknown';
    let categoryIdx = Number.MAX_VALUE;
    let gender = 'Unknown';

    for (const row of eventResults) {
        if (row["start_date"] < lastDateFmt) {
            continue;
        }
        const eventName = row["event_name"] as string;
        for (let loopCat = 0; loopCat < KNOWN_CATEGORIES.length; loopCat++) {
            const oneCat = KNOWN_CATEGORIES[loopCat];
            // console.log(JSON.stringify([eventName, oneCat, eventName.includes(oneCat)]));
            if (eventName.includes(oneCat)) {
                if (loopCat < categoryIdx) {
                    category = oneCat;
                    categoryIdx = loopCat;
                    // console.log('Setting category', category, categoryIdx, row["start_date"], eventName);
                }
            }
        }
        for (const oneGender of KNOWN_GENDERS) {
            if (eventName.includes(oneGender)) {
                gender = oneGender;
            }
        }
    }

    return { category, gender };
}
