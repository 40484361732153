import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DataRow, RenderCellFunc, SimpleTable } from "./SimpleTable";
import { StyleNavLink } from "./StyleNavLink";
import { LinkIcon } from '@heroicons/react/24/solid';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import { fetchSql, fixAmp } from "./helpers";
import { Row } from "react-table";
import { LINK_CLASS_NAME, clubCell, clubLink, directElimsCell, eventResultCell, fencerCell, googleLink, newRatingCell, poolsCell } from "./CellHelpers";
import { SqlRow } from "./ToolCrawl";
import { getFencerInfo } from "./FencerHelpers";
import { Table } from "./table";
import { ShowIf } from "./ShowIf";


export const linkIcon = (<LinkIcon style={{ display: "inline", width: 12, height: 12, fill: "blue" }} />);

const getRowClassName = (row: Row): string => {
    // console.log("rowClassName", row.original);
    if ((row.original as any).won) {
        return "bg-green-100";
    } else {
        return "bg-red-100";
    }
};


const eventResultsColumns = [
    { Header: 'Date', accessor: "start_date" },
    { Header: 'Event', accessor: "event_name", Cell: eventResultCell },
    { Header: 'Place', accessor: "place_num" },
    { Header: 'Num Fencers', accessor: "num_fencer" },
    { Header: 'New Rating', accessor: "new_rating", Cell: newRatingCell },
    { Header: 'Pools', accessor: "", Cell: poolsCell },
    { Header: 'DEs', accessor: "", Cell: directElimsCell },
    { Header: 'Tourney', accessor: "tourney_name" },
    { Header: 'Location', accessor: "tourney_location" },
];


const tableauMatchesColumns = [
    { Header: 'Date', accessor: "start_date" },
    { Header: 'Event', accessor: "event_name", Cell: eventResultCell },
    { Header: 'Won', accessor: "wonStr" },
    { Header: 'Score', accessor: "score" },
    { Header: 'Opponent', accessor: "other_name", Cell: fencerCell },
    { Header: 'Opp. Rating', accessor: "other_old_rating" },
    { Header: 'Expect Win %', accessor: "fencer_expect_win" },
    { Header: 'Fencer ELO', accessor: "fencer_before_elo" },
    { Header: 'Opponent ELO', accessor: "other_before_elo" },
    { Header: 'ELO +/-', accessor: "fencer_elo_diff" },
    { Header: 'ELO Bout #', accessor: "elo_bout_num" },
];


const poolColumns = [
    { Header: 'Date', accessor: "start_date" },
    { Header: 'Event', accessor: "event_name", Cell: eventResultCell },
    { Header: 'Order', accessor: "fencer_order" },
    { Header: 'Won', accessor: "wonStr" },
    { Header: 'Score', accessor: "score" },
    { Header: 'Opponent', accessor: "other_name", Cell: fencerCell },
    { Header: 'Opp. Rating', accessor: "other_old_rating" },
    { Header: 'Expect Win %', accessor: "fencer_expect_win" },
    { Header: 'Fencer ELO', accessor: "fencer_before_elo" },
    { Header: 'Opponent ELO', accessor: "other_before_elo" },
    { Header: 'ELO +/-', accessor: "fencer_elo_diff" },
    { Header: 'ELO Bout #', accessor: "elo_bout_num" },
];

const topEventColumns = [
    { Header: 'Date', accessor: "start_date" },
    { Header: 'Event', accessor: "event_name", Cell: eventResultCell },
    { Header: 'Place', accessor: "place" },
    { Header: 'Num Fencers', accessor: "num_fencer" },
    { Header: 'Pools', accessor: "", Cell: poolsCell },
    { Header: 'DEs', accessor: "", Cell: directElimsCell },
    { Header: 'Tourney', accessor: "tourney_name" },
    { Header: 'Location', accessor: "tourney_location" },
];

const freqOpponentColumns = [
    { Header: 'Opponent', accessor: "other_name", Cell: fencerCell },
    { Header: 'Opponent Club', accessor: "other_club1", Cell: clubCell },
    { Header: 'Num DEs', accessor: "num_de" },
    { Header: 'Num Win', accessor: "num_win" },
    { Header: 'Win %', accessor: "win_per" },
    { Header: 'Last DE', accessor: "last_date" },
    { Header: 'First DE', accessor: "first_date" },
];

const renderEventCell: RenderCellFunc = (rowIdx: number, colIdx: number, row: DataRow, field: string) => {
    if (field === "event_name") {
        return <><a className={LINK_CLASS_NAME} href={"https://fencingtimelive.com/events/results/" + row.event_id} target="_blank" >{linkIcon} {row.event_name}</a></>
    } else {
        return <>{row[field]}</>;
    }
}

const renderTableauCell: RenderCellFunc = (rowIdx: number, colIdx: number, row: DataRow, field: string) => {
    if (field === "otherBoth") {
        return <><StyleNavLink to={"/fencer/" + row["other_name"]}>{fixAmp(row.other_name as string)}</StyleNavLink>{" (" + row.other_seed + ") "}</>
    } else {
        return <>{row[field]}</>;
    }
}

const renderPoolCell: RenderCellFunc = (rowIdx: number, colIdx: number, row: DataRow, field: string) => {
    if (field === "other_name") {
        return <><StyleNavLink to={"/fencer/" + row["other_name"]}>{fixAmp(row.other_name as string)}</StyleNavLink></>
    } else {
        return <>{row[field]}</>;
    }
}

const poolSortBy = [{ id: "start_date", desc: true }, { id: "event_name", desc: false }, { id: "fencer_order", desc: false }];
const freqSortBy = [{ id: "num_de", desc: true }];
const eventsSortBy = [{ id: "start_date", desc: true }];

export function Fencer() {
    const { fencerName } = useParams();
    const [eventResults, setEventResults] = useState([] as SqlRow[]);
    const [tableauMatches, setTableauMatches] = useState([] as SqlRow[]);
    const [pools, setPools] = useState([] as SqlRow[]);
    const [recentInfo, setRecentInfo] = useState([] as SqlRow[]);
    const [topEvents, setTopEvents] = useState([] as SqlRow[]);
    const [topBigEvents, setTopBigEvents] = useState([] as SqlRow[]);
    const [freqOpponent, setFreqOpponent] = useState([] as SqlRow[]);

    useEffect(() => {
        const f = async () => {
            setEventResults([]);
            setTableauMatches([]);
            setPools([]);
            setRecentInfo([]);
            setTopEvents([]);
            setTopBigEvents([]);
            setFreqOpponent([]);

            // Just a construct to run all of these queries in parallel (with Promise.all below)
            // Is this slower?
            const promises = [
                (async () => {
                    const postData = { queryName: "fencer_events", queryParams: { "$fencer_name": fencerName } };
                    const { rows, err } = await fetchSql(postData);
                    setEventResults(rows || []);
                })(),
                (async () => {
                    const postData = { queryName: "fencer_tableau_matches_elo", queryParams: { "$fencer_name": fencerName } };
                    const { rows, err } = await fetchSql(postData);
                    const newRows = (rows || []).map((row: any) => {
                        return {
                            ...row,
                            fencerBoth: `(${row.fencer_seed}) ${row.fencer_name}`,
                            otherBoth: `(${row.other_seed}) ${row.other_name}`,
                            wonStr: (row.won) ? "Won" : "Lost"
                        }
                    })
                    setTableauMatches(newRows);
                })(),
                (async () => {
                    const postData = { queryName: "fencer_pool_matches_elo", queryParams: { "$fencer_name": fencerName } };
                    const { rows, err } = await fetchSql(postData);
                    const newRows = (rows || []).map((row: any) => {
                        return {
                            ...row,
                            wonStr: (row.won) ? "Won" : "Lost"
                        }
                    })
                    setPools(newRows);
                })(),
                (async () => {
                    const postData = { queryName: "fencer_recent_info", queryParams: { "$fencer_name": fencerName } };
                    const { rows, err } = await fetchSql(postData);
                    setRecentInfo(rows || []);
                })(),
                (async () => {
                    const postData = { queryName: "fencer_top_5_events", queryParams: { "$fencer_name": fencerName } };
                    const { rows, err } = await fetchSql(postData);
                    setTopEvents(rows || []);
                })(),
                (async () => {
                    const postData = { queryName: "fencer_top_5_big_events", queryParams: { "$fencer_name": fencerName } };
                    const { rows, err } = await fetchSql(postData);
                    setTopBigEvents(rows || []);
                })(),
                (async () => {
                    const postData = { queryName: "fencer_frequent_opponent", queryParams: { "$fencer_name": fencerName } };
                    const { rows, err } = await fetchSql(postData);
                    setFreqOpponent(rows || []);
                })(),
            ];
            await Promise.all(promises);
        }
        f();
    }, [fencerName]);

    const infoRowDE = (recentInfo?.[0] || {}) as any;
    const fixFencerName = fixAmp(fencerName!);

    const { category, gender } = getFencerInfo(eventResults);

    const tableauPer = (infoRowDE?.tableau_percentile) ? `(top ${infoRowDE?.tableau_percentile.toFixed(1)}%)` : '';
    const poolsPer = (infoRowDE?.pools_percentile) ? `(top ${infoRowDE?.pools_percentile.toFixed(1)}%)` : '';

    return (
        <div>
            <div className="bg-blue-300 mb-4 pt-2 pb-2 flex flex-col text-center">
                <span className="text-2xl">{fixFencerName}</span>
                {<>
                    <div>
                        <span className="ml-4 mr-4"><b>{category} {gender}</b></span>
                        <span className="ml-4 mr-4">Rating <b>{infoRowDE?.fencer_old_rating}</b></span>
                        <span className="ml-4 mr-4">DEs ELO <b>{infoRowDE?.fencer_before_elo}</b> {tableauPer}</span>
                        <span className="ml-4 mr-4">Pools ELO <b>{infoRowDE?.fencer_before_elo_pools}</b> {poolsPer}</span>
                    </div>
                    <div>
                        <span className="ml-4 mr-4">{clubLink(infoRowDE?.fencer_club1)}</span>
                        <ShowIf show={infoRowDE?.fencer_club2 && infoRowDE?.fencer_club1 !== infoRowDE?.fencer_club2}>
                            <span className="ml-4 mr-4">{clubLink(infoRowDE?.fencer_club2)}</span>
                        </ShowIf>
                        <span className="ml-4 mr-4">{googleLink("fencing " + fixFencerName, `on Google`)}</span>
                    </div>
                </>
                }
            </div>

            <Tabs>
                <TabList>
                    <Tab>Overview</Tab>
                    <Tab>Event Results</Tab>
                    <Tab>Tableau Bouts</Tab>
                    <Tab>Pool Bouts</Tab>
                    <Tab>Frequent Opponents DEs</Tab>
                </TabList>

                <TabPanel>
                    <div className="mr-2 ml-2 mt-4">
                        <div className="text-xl text-center mb-4 bg-gray-300">Top Events</div>
                        <Table data={topEvents} columns={topEventColumns} paginate={false} ></Table>
                    </div>
                    <div className="mr-2 ml-2 mt-8">
                        <div className=" text-xl text-center mb-4 bg-gray-300">Top Big Events (&gt;64 fencers)</div>
                        <Table data={topBigEvents} columns={topEventColumns} paginate={false} ></Table>
                    </div>
                </TabPanel>

                <TabPanel>
                    {/* <TournamentSet data={eventResults} loading={false} /> */}
                    <div className="mr-2 ml-2">
                        <Table data={eventResults} columns={eventResultsColumns} sortBy={eventsSortBy} ></Table>
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className="mr-2 ml-2">
                        <Table data={tableauMatches} columns={tableauMatchesColumns} sortBy={poolSortBy} getRowClassName={getRowClassName} ></Table>
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className="mr-2 ml-2">
                        <Table data={pools} columns={poolColumns} sortBy={poolSortBy} getRowClassName={getRowClassName} ></Table>
                    </div>
                </TabPanel>

                <TabPanel>
                    <div className="mr-2 ml-2">
                        <div className=" text-xl text-center mb-4 bg-gray-300">Frequent Opponents</div>
                        <Table data={freqOpponent} columns={freqOpponentColumns} sortBy={freqSortBy} paginate={false}></Table>
                    </div>
                </TabPanel>
            </Tabs>
        </div>

    )
}