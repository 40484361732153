
import { useState, useEffect } from 'react';
import { fetchSql } from './helpers';
import { TournamentSet } from './TournamentSet';


export interface AllTournamentsProps {
}


export function AllTournaments(props: AllTournamentsProps) {
    const [showRows, setShowRows] = useState(5000);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([] as unknown as any[]);

    const onShowMore = () => {
        setShowRows(showRows * 2);
    }

    useEffect(() => {
        const f = async () => {
            setLoading(true);

            const postData = { queryName: "all_fencer_events", queryParams: { "$row_limit": showRows } };
            const { rows, err } = await fetchSql(postData);

            setData(rows || []);
            setLoading(false);
        }
        f();
    }, [showRows]);

    return (
        <div>
            <TournamentSet data={data} loading={loading} onShowMore={onShowMore} />
        </div>
    );
}
