import { useEffect, useState } from "react";
import { TableCellsIcon } from '@heroicons/react/24/solid';
import { DataRow, RenderCellFunc, RowStyleFunc, SimpleTable } from "./SimpleTable";
import { StyleNavLink } from "./StyleNavLink";
import { fetchSql } from "./helpers";
import { LINK_CLASS_NAME } from "./CellHelpers";
import { SqlRow } from "./ToolCrawl";


export const downIcon = (<TableCellsIcon style={{ display: "inline", width: 12, height: 12, fill: "blue" }} />);

const renderPoolCell: RenderCellFunc = (rowIdx: number, colIdx: number, row: DataRow, field: string) => {
    if (field === "other_name") {
        return <><StyleNavLink to={"/fencer/" + row["other_name"]}>{row.other_name}</StyleNavLink></>
    } else {
        return <>{row[field]}</>;
    }
}

export const rowStyleFunc: RowStyleFunc = (rowIdx: number, row: DataRow) => {
    return (row.won) ? { backgroundColor: "#c0ffc0" } : { backgroundColor: "#ffc0c0" };
}


const poolColumns = [
    { field: "fixOrder" },
    { field: "fencer_name" },
    { field: "wonStr" },
    { field: "score" },
    { field: "other_name" },
];


export interface PoolsProps {
    eventId: string;
    fencerName: string;
}

export const Pools = (props: PoolsProps) => {
    const { eventId, fencerName } = props;
    const [show, setShow] = useState(false);
    const [startedLoad, setStartedLoad] = useState(false);
    const [finishedLoading, setSetFinishedLoading] = useState(false);
    const [results, setResults] = useState([] as SqlRow[]);

    useEffect(() => {
        const f = async () => {
            if (startedLoad) {
                const postData = { queryName: "fencer_event_pool_results", queryParams: { "$event_id": eventId, "$fencer_name": fencerName } };
                const { rows, err } = await fetchSql(postData);
                if (rows) {
                    const newRows = rows.map((row: any) => { return { ...row, wonStr: (row.won) ? "Won" : "Lost", fixOrder: row.fencer_order + 1 } });
                    setResults(newRows);
                }
                setSetFinishedLoading(true);
            }
        }
        f();
    }, [eventId, fencerName, startedLoad]);

    const toggleShow = () => {
        setShow(!show);
        if (!startedLoad) {
            setStartedLoad(true);
        }
    }

    const numWon = results.map((row: any) => row.won).reduce((partial, a) => partial + a, 0);
    const numLost = results.map((row: any) => 1 - row.won).reduce((partial, a) => partial + a, 0);


    return (
        <>
            <div style={{ cursor: "pointer" }}>
                <a className={LINK_CLASS_NAME} onClick={toggleShow}>{(!show) ? <>{downIcon} Pools</> : <>{downIcon} Hide</>}</a>
            </div>
            {(show && startedLoad && finishedLoading) ? (
                <div style={{ backgroundColor: "#EEE" }}>
                    <div>
                        <h4 style={{ textAlign: "center" }}>Won {numWon} - Lost {numLost}</h4>
                    </div>
                    <SimpleTable columns={poolColumns} data={results} renderCell={renderPoolCell} rowStyleFunc={rowStyleFunc} />
                </div>
            ) : (
                <></>
            )}
            {(show && startedLoad && !finishedLoading) ? (
                <div>
                    Loading...
                </div>
            ) : (
                <></>
            )}
        </>)
}


