import { CSSProperties } from 'react';
import { fixAmp } from './helpers';


interface ColumnDesc {
    field: string;
    header?: string;
}

export type DataRow = {[key:string]: string|number};

export type RenderCellFunc = (rowIdx: number, colIdx: number, row: DataRow, field: string) => JSX.Element;
export type RowStyleFunc = (rowIdx: number, row: DataRow) => CSSProperties;

interface SimpleTableProps {
    columns: ColumnDesc[];
    data: DataRow[];
    style?: CSSProperties;
    renderCell?: RenderCellFunc;
    rowStyleFunc?: RowStyleFunc;
}

export const SimpleTable = (props: SimpleTableProps) => {
    const { data, renderCell, style, rowStyleFunc } = props;

    return (
        <div className="w-full flex justify-center">
        <table cellPadding={8} cellSpacing={0} border={0} style={style}>
            <tbody>
                {data.map((dataRow: any, rowIdx) => {
                    const rowStyle = (rowStyleFunc) ? rowStyleFunc(rowIdx, dataRow) : {};

                    return (<tr style={rowStyle} key={rowIdx}>
                        {props.columns.map((colDesc, colIdx) => {
                            let val: string | JSX.Element = fixAmp(dataRow[colDesc.field]);
                            if (renderCell) {
                                val = renderCell(rowIdx, colIdx, dataRow, colDesc.field) || val;
                            }

                            return (<td key={colIdx}>{val}</td>);
                        })}
                    </tr>);
                })}
            </tbody>
        </table>
        </div>
    );
};
