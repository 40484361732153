import { DataRow, RenderCellFunc, SimpleTable } from "./SimpleTable";
import { groupBy } from 'underscore';
import { StyleNavLink } from "./StyleNavLink";
import { Pools } from "./Pools";
import { DirectElims } from "./DirectElims";
import { LinkIcon } from '@heroicons/react/24/solid';
import { fixAmp } from "./helpers";
import { LINK_CLASS_NAME } from "./CellHelpers";


export const linkIcon = (<LinkIcon style={{ display: "inline", width: 12, height: 12, fill: "blue" }} />);


interface TournamentProps {
    name: string;
    data: {}[];
}


interface EventProps {
    name: string;
    eventId: string;
    data: {}[];
}


const columns = [
    { field: "place" },
    { field: "name" },
    { field: "old_rating" },
    { field: "new_rating" },
    { field: "club1" },
    { field: "pools" },
    { field: "direct_elims" },
];

const renderEventCell: RenderCellFunc = (rowIdx: number, colIdx: number, row: DataRow, field: string) => {
    if (field === "club1") {
        return <StyleNavLink to={"/club/" + row[field]}>{row[field]}</StyleNavLink>
    } else if (field === "new_rating") {
        return <b style={{ backgroundColor: "green", color: "white" }}>{row[field]}</b>
    } else if (field === "pools") {
        return <Pools eventId={row.event_id as string} fencerName={row.name as string} />;
    } else if (field === "direct_elims") {
        return <DirectElims eventId={row.event_id as string} fencerName={row.name as string} />;
    } else if (field === "name") {
        return <><StyleNavLink to={"/fencer/" + row["name"]}>{row.name}</StyleNavLink></>
    } else {
        return <>{row[field]}</>;
    }
}



export const Event = (props: EventProps) => {
    const numFencer = (props.data[0] as any)?.numFencer;

    const link = "/event_results/" + props.eventId;

    return (
        <div>
            <div className="text-xl bg-green-200 mt-2 pt-2 mb-1 pb-1 flex flex-col text-center">
                <span className="text-xl">
                    <a className={LINK_CLASS_NAME} href={link}>{fixAmp(props.name)}</a>
                </span>
                <span>{numFencer} fencers</span>
            </div>
            <SimpleTable columns={columns} data={props.data} renderCell={renderEventCell} />
        </div>
    );
}


export const Tournament = (props: TournamentProps) => {
    const eventGroups: any = groupBy(props.data, (val: any) => JSON.stringify([val.event, val.event_id]));
    const date = (props.data[0] as any)?.tourneyDate;
    const tourneyLoc = (props.data[0] as any)?.tourneyLoc;

    return (
        <div>
            <div className="bg-blue-200 mt-4 mb-4 pt-2 pb-2 flex flex-col text-center">
                <span className="text-2xl">{fixAmp(props.name)}</span>
                <div>
                    <span className="mr-4">{date}</span>
                    <span>{tourneyLoc}</span>
                </div>
            </div>
            {Object.keys(eventGroups).map(eventGroupKey => {
                const eventData = eventGroups[eventGroupKey];
                const eventKeyParsed = JSON.parse(eventGroupKey);
                const eventId = eventKeyParsed[1];
                return <Event name={eventKeyParsed[0]} data={eventData} eventId={eventId} key={eventGroupKey}/>
            })}
        </div>
    );
}
