import { useEffect, useRef } from 'react';
import { CellFunc, Table, createColsFromData } from '../table';
import { Cell, Row } from 'react-table';
import { ShowIf } from '../ShowIf';
import { fixAmp } from '../helpers';
import { observer } from "mobx-react-lite"


export interface SimShowBracketProps {
    bracket: any;
    overallWinner?: string;
    autoScrollToHighlight?: boolean;
}


const fmtPlayerSeed = (playerSeed: any): string => {
    if (!playerSeed) {
        return ` - BYE -`;
    } else {
        return `(${playerSeed.seed}) ${playerSeed.player}`;
    }
}

interface BoutProps {
    row: number;
}


/**
 * 
 * @param inverseRound - in this context, inverseRound 0 is the HIGHEST table (e.g., Table of 64)
 * @param boutNum - boutNum within the round.  In Table of 64, there are 32 bouts, so boutNum = 0..31
 * @param playerIdx - 0 for player1, 1 for player2
 */
const getBoutProps = (inverseRound: number, boutNum: number, playerIdx: number): BoutProps => {
    if (inverseRound === 0) {
        const row = 4 * boutNum + playerIdx * 2;
        return { row };
    } else {
        // Now for some recursion magic - we base this round off of the previous round
        const prevRound = inverseRound - 1;
        const prevBoutNum = boutNum * 2 + playerIdx;
        // We Run prev query twice, for a playerIdx of 0 and 1, and averge them
        const row0 = getBoutProps(prevRound, prevBoutNum, 0).row;
        const row1 = getBoutProps(prevRound, prevBoutNum, 1).row;
        const row = Math.trunc((row0 + row1) / 2);
        return { row };
    }
}


const convertBracketToFlatTable = (bracket: any) => {
    const flat: Row[] = [];

    let maxRow = 0;

    const getRow = (row: number): any => {
        if (row > maxRow) {
            maxRow = row;
        }

        flat[row] = flat[row] || {};
        return flat[row];
    }

    const setPlayerCell = (roundName: string, inverseRound: number, boutNum: number, playerIdx: number, desc: string, prop: string) => {
        const row = getBoutProps(inverseRound, boutNum, playerIdx).row;
        getRow(row)[roundName] = desc;
        getRow(row)[roundName + "_props"] = prop;
        return row;
    }

    for (let i = 0; i < (bracket || [])?.rounds?.length; i++) {
        const inverseIdx = bracket.rounds.length - 1 - i;
        const round = bracket.rounds[inverseIdx];
        const roundName = "Table " + Math.pow(2, inverseIdx + 1).toString();
        const nextRoundName = "Table " + Math.pow(2, inverseIdx).toString();
        for (const oneBout of round) {
            const row0 = setPlayerCell(roundName, i, oneBout.boutNum, 0, fmtPlayerSeed(oneBout?.player1), "bottom");
            const row1 = setPlayerCell(roundName, i, oneBout.boutNum, 1, fmtPlayerSeed(oneBout?.player2), "bottom");

            for (let row = row0+1; row <= row1; row++) {
                getRow(row)[roundName + "_props"] = (getRow(row)[roundName + "_props"] || '') + "right";
            }

            if (inverseIdx === 0) {
                setPlayerCell(nextRoundName, i+1, 0, 0, fmtPlayerSeed(oneBout?.winner), "bottom");
            }
        }
    }

    // Fill in the blank values
    for (let i = 0; i <= maxRow; i++) {
        getRow(i);  // Side effect creates the dict for that row
    }

    return flat;
}


export const SimShowBracket = observer((props: SimShowBracketProps) => {
    const { bracket, overallWinner, autoScrollToHighlight } = props;

    const winnerElem = useRef(undefined as any as HTMLSpanElement);


    useEffect(() => {
        const doScroll = window && winnerElem.current && autoScrollToHighlight;
        if (doScroll) {
            window.requestAnimationFrame(() => {
                winnerElem.current.scrollIntoView({behavior:"smooth"});
            });
        }
    }, [bracket, overallWinner, winnerElem, autoScrollToHighlight]);

    // TODO - useMemo
    const flat = convertBracketToFlatTable(bracket);

    const bracketCell: CellFunc = (params: any): JSX.Element => {
        const { value, row, cell } : { value: string | number, row: Row, cell: Cell } = params;
        const isWinner = value?.toString()?.includes(overallWinner!);
        const doScroll = params.allColumns[0].Header === cell.column.Header;    // Check that this is column 0
        return (<span ref={(doScroll && isWinner) ? winnerElem : undefined} className={`${isWinner ? " bg-purple-500" : ""}`} >{fixAmp(value?.toString())}</span>);
    };

    const getCellClassName = (row: Row, cell: Cell): string => {
        const field = cell.column.id;
        const propsField = field + "_props";

        const hasBottomBorder = (row.original as any)[propsField]?.includes("bottom");
        const bottomBorderClass = (hasBottomBorder) ? "border-b border-black" : "";

        const hasRightBorder = (row.original as any)[propsField]?.includes("right");
        const rightBorderClass = (hasRightBorder) ? "border-r border-black" : "";
        
        return `${bottomBorderClass} ${rightBorderClass}`;
    }

    const tableCols = createColsFromData(flat).map(col => {
        return { ...col, Cell: bracketCell };
    }).filter(col => !col.accessor.includes("_props"));

    return (
        <div className="w-full pr-8 pl-8">
            <ShowIf show={flat.length === 0}>
                Click a player in the "Output" tab to show their bracket here.
            </ShowIf>
            <ShowIf show={flat.length > 0}>
                <Table 
                    className="mb-2"
                    data={flat} 
                    columns={tableCols} 
                    initPageSize={1000} 
                    paginate={false} 
                    disableStriping={true} 
                    disableBorder={true}
                    getRowClassName={(row: Row) => "bg-yellow-100"}
                    getCellClassName={getCellClassName}
                    wrappable={true}
                />
            </ShowIf>
        </div>
    )
});
