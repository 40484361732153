import { useEffect, useState } from "react";
import { apiFetch } from "../helpers";


const API_CALL_INTERVAL = 250;
const MAX_ERROR = 1;



type CompleteFunc<T> = (results: T) => void;


export const useRemoteJobRunner = <T>(jobId: string | undefined, path: string, callProps: any, onComplete?: CompleteFunc<T>) => {
    const [apiStatus, setApiStatus] = useState([] as string[]);
    const [apiResult, setApiResult] = useState(undefined as T | undefined);
    const [apiError, setApiError] = useState(undefined as any);
    const [loading, setLoading] = useState(undefined as any);

    useEffect(() => {
        // console.log("useRemoteJobRunner", jobId, callProps);

        let lastApiCall = 0;
        let numError = 0;
        let apiCallInFlight = false;
        let timer: any;
        let curLines: string[] = [`Running Watch Event request ${callProps}`];        // We keep a copy because state doesn't update inside this function
        setLoading(true);
        setApiStatus(curLines);
        setApiResult(undefined);
        setApiError(undefined);
        if (!jobId) {
            setLoading(false);
            return;
        }
        const intervalCallback = async () => {
            if (Date.now() - lastApiCall >= API_CALL_INTERVAL && !apiCallInFlight) {
                lastApiCall = Date.now();
                apiCallInFlight = true;
                const postData = Object.assign({}, JSON.parse(callProps), { jobId, curLine: curLines.length });
                const apiResults = await apiFetch(path, postData);
                if (apiResults.logLines) {
                    const both = curLines.concat(apiResults.logLines);
                    setApiStatus(both);
                    curLines = both;
                }
                if (apiResults.result) {
                    setApiResult(apiResults.result);
                    if (onComplete) {
                        onComplete(apiResults.result);
                    }
                    setLoading(false);
                    clearInterval(timer);
                }
                if (apiResults.err) {
                    numError++;
                    setApiError(apiResults.err);
                    if (numError >= MAX_ERROR) {
                        setLoading(false);
                        clearInterval(timer);
                    }
                }
                apiCallInFlight = false;
            }
        };
        const f = async () => {
            timer = setInterval(intervalCallback, 100);
        }
        f();
        return () => {
            clearInterval(timer);
        };
    }, [jobId, callProps]);

    return { loading, apiStatus, apiResult, apiError };
};

