import { CellFunc, Table, createColsFromData } from "./table";
import { useRemoteJobRunner } from "./Simulator/remote_job_runner";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { observer } from "mobx-react-lite"
import { uiState } from "../stores/ui_store";
import { ShowIf } from "./ShowIf";
import { VirtualList } from "./VirualList";
import { StyleNavLink } from "./StyleNavLink";
import { fixAmp } from "./helpers";
import { fencerCell } from "./CellHelpers";
import { WATCH_EVENT_TEST_DATA } from "./WatchEventTestData";


export type SqlRow = Record<string, string | number>;


export interface WatchEventDataProps {
    crawlUrl: string;
    submitTime: number;
}

export interface IPoolRow {
    name: string;
    V?: number | null;          // Victories
    VM?: number | null;         // Victories / Matches
    TS?: number | null;         // Touches scored   
    TR?: number | null;         // Touches received
    Ind?: number | null;        // Indicator - total 
    bouts: string[];    // e.g., "D4", "V5"
}


export interface IParsedPoolData {
    poolNum: number;
    poolResults: IPoolRow[];
}


export interface FencerEloRating {
    fencer_before_elo: number;
    fencer_before_elo_pools: number;
    fencer_old_rating: string;
}


export interface WatchEventResults {
    seedRows: SqlRow[];
    poolResultsRows: SqlRow[];
    eventResultsRows: SqlRow[];
    poolsIsDone: boolean;
    eventIsDone: boolean;
    tableauParseResults: any;
    poolsScores: IParsedPoolData[];
    fencerElos: {[key: string]: FencerEloRating};
}


enum FencerColor {
    WINNER,
    LOSER,
    NEUTRAL
}

function playerLink(fencer: string, seed: string, color: FencerColor): any {
    const colorMap = {
        [FencerColor.WINNER]: "text-green-500",
        [FencerColor.LOSER]: "text-red-500",
        [FencerColor.NEUTRAL]: "text-inherit",
    }
    const textColor = colorMap[color];
    return <>
        <StyleNavLink to={"/fencer/" + fencer}>
            <span className={textColor}>{fixAmp(fencer as string)}</span>
        </StyleNavLink>
        {" (" + seed + ") "}
    </>
}


export const resultsCell: CellFunc = ({ value, row }: { value: string | number, row: any }): JSX.Element => {
    const orig = row.original;
    const winnerColor = (orig.winner === orig.Name) ? FencerColor.WINNER : FencerColor.NEUTRAL;
    const loserColor = (orig.loser === orig.Name) ? FencerColor.LOSER : FencerColor.NEUTRAL;
    return (orig.winner) ? <>
        {playerLink(orig.winner, orig.winnerSeed, winnerColor)} beat {playerLink(orig.loser, orig.loserSeed, loserColor)} {orig.score} in table {orig.table}
        </> : <></>
};


const summaryCols = [
    { Header: 'Seed', accessor: "Seed" },
    { Header: 'Name', accessor: "Name", Cell: fencerCell  },
    { Header: 'Class/Rank', accessor: "Class/Rank" },
    { Header: 'Club(s)', accessor: "Club(s)" },
    { Header: 'Division', accessor: "Division" },
    { Header: 'Country', accessor: "Country" },
    { Header: 'Pools', accessor: "Pools" },
    { Header: 'Results', accessor: "Results" },
    { Header: 'Last Match', accessor: "lastMatch", Cell: resultsCell },
];


// TODO - move to utils
function fixPlace(place: string): number {
    if (place === 'DNF' || place.toLowerCase().includes('medical')) {
        return 9999;
    }
    return parseInt(place.toString().toLowerCase().replaceAll("t", ""));
}


interface AnalyzedRow {
    poolNum: number;
    num: number;
    avgElo: number;
}


function analyzePools(data: WatchEventResults) {
    const analyzed: AnalyzedRow[] = [];

    // Loop over the pools
    for (const onePool of data?.poolsScores || []) {
        let num = 0;
        let totElo = 0;
        const sorted = [];
        for (const poolRow of onePool.poolResults) {
            const fencerName = poolRow.name;
            const fencerPoolsElo = data.fencerElos[fencerName]?.fencer_before_elo_pools;
            if (fencerPoolsElo) {
                num += 1;
                totElo += fencerPoolsElo;
            }
            sorted.push({ name: fencerName, elo: fencerPoolsElo });
        }

        sorted.sort((a, b) => b.elo - a.elo);

        const row = {
            poolNum: onePool.poolNum, 
            num, 
            avgElo: Math.trunc(totElo / num) 
        };

        let idx = 0;
        for (const oneElo of sorted) {
            idx++;
            (row as any)['name'+idx] = oneElo.name;
            (row as any)['elo'+idx] = oneElo.elo;
        }

        analyzed.push(row);
    }

    return analyzed;
}


export const WatchEventData = observer((props: WatchEventDataProps) => {
    const propsStr = JSON.stringify(props);

    const { loading, apiStatus, apiResult, apiError } = useRemoteJobRunner<WatchEventResults>("1", "watchEvent", propsStr);
    // const apiResult: WatchEventResults = WATCH_EVENT_TEST_DATA;
    // const loading = false;
    // const apiError = null;
    // const apiStatus: any = null;

    const analyzedPools = analyzePools(apiResult!);
    const analyzedPoolsCols = createColsFromData(analyzedPools);
    console.log(analyzedPoolsCols)


    const seedData = apiResult?.seedRows;
    const seedColumns = (seedData) ? createColsFromData(seedData) : [];

    const poolsData = apiResult?.poolResultsRows;
    const poolsColumns = (poolsData) ? createColsFromData(poolsData) : [];

    const eventData = apiResult?.eventResultsRows;
    const eventColumns = (poolsData) ? createColsFromData(poolsData) : [];

    const parsedMatches = apiResult?.tableauParseResults?.parsedMatches;

    const onSelectTab = (index: number, _lastIndex: number, _event: Event): boolean => {
        uiState.setWatchEventActiveTab(index);
        return true;
    };

    const origSummaryData: SqlRow[] = seedData?.filter(row => row["Club(s)"].toString().includes("Windy")) || [];
    const withPools = origSummaryData.map(row => {
        const newRow = Object.assign({}, row);
        const poolsPlace = poolsData?.find(poolsRow => poolsRow.Name === row.Name)?.Place;
        newRow["Pools"] = (poolsPlace) ? fixPlace(poolsPlace.toString()) : "Pending";
        return newRow;
    })
    const withEvents = withPools.map(row => {
        const newRow = Object.assign({}, row);
        const resultsPlace = eventData?.find(eventRow => eventRow.Name === row.Name)?.Place;
        newRow.resultsPlace = resultsPlace!;
        const lastMatchAry = parsedMatches?.filter((pmRow: any) => pmRow.winner === row.Name || pmRow.loser === row.Name);
        lastMatchAry?.sort((a: any, b: any) => a.table - b.table);
        const lastMatch = lastMatchAry?.[0];
        if (lastMatch) {
            newRow.winner = lastMatch.winner;
            newRow.winnerSeed = lastMatch.winnerSeed;
            newRow.loser = lastMatch.loser;
            newRow.loserSeed = lastMatch.loserSeed;
            newRow.score = lastMatch.score;
            newRow.table = lastMatch.table;
            newRow.lastMatch = "*";
        }
        newRow.Results = (resultsPlace) ? resultsPlace : "Pending";
        return newRow;
    })

    const summaryData = withEvents;

    const tabs = (
        <Tabs selectedIndex={uiState.watchEventActiveTab} onSelect={onSelectTab}>
            <TabList>
                <Tab>Summary</Tab>
                <Tab>Seeding</Tab>
                <Tab>Pools</Tab>
                <Tab>Results</Tab>
                <Tab>Pools Analysis</Tab>
            </TabList>

            <TabPanel>
                <Table data={summaryData} columns={summaryCols} paginate={true} initPageSize={100} />
            </TabPanel>
            <TabPanel>
                <Table data={seedData!} columns={seedColumns} paginate={true} initPageSize={100} />
            </TabPanel>
            <TabPanel>
                <Table data={poolsData!} columns={poolsColumns} paginate={true} initPageSize={100} />
            </TabPanel>
            <TabPanel>
                <Table data={eventData!} columns={eventColumns} paginate={true} initPageSize={100} />
            </TabPanel>
            <TabPanel>
                <h2 className="bg-cyan-300">Toughest pool is at the top (sorting by average ELO descending)</h2>
                <Table data={analyzedPools!} columns={analyzedPoolsCols} paginate={true} initPageSize={100} sortBy={[{ id: "avgElo", desc: true }]} />
            </TabPanel>
        </Tabs>
    );

    return (
        <div>
            <div className="mr-2 ml-2 mt-8">
                <div className=" text-xl text-center mb-4 bg-gray-300">Watched Event</div>
                <ShowIf show={(loading || !!apiError) && apiStatus && apiStatus?.length > 0}>
                    <VirtualList items={apiStatus || []} />
                </ShowIf>
                {(apiError)
                    ? <div className="text-red-500">{apiError}</div>
                    : <></>
                }
                <ShowIf show={!loading && !!poolsData}>
                    {tabs}
                </ShowIf>
            </div>
        </div>
    );
});
