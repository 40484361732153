import { Row } from "react-table";
import { Pools } from "./Pools";
import { CellFunc } from "./table";
import { StyleNavLink } from "./StyleNavLink";
import { DirectElims } from "./DirectElims";
import { fixAmp } from "./helpers";


export const LINK_CLASS_NAME = "underline decoration-blue-400 decoration-[.08em] cursor-pointer";


export const newRatingCell: CellFunc = ({ value }: { value: string | number }): JSX.Element => {
    return (value) ? (
        <b className="bg-green-700 text-white pt-1 pb-1 pr-1 pl-1">{value}</b>
    ) : (<></>);
};


export const poolsCell: CellFunc = ({ value, row }: { value: string | number, row: any }): JSX.Element => {
    // console.log("poolsCell", row, row.event_id, row.fencer_name);
    return (<Pools eventId={row.original.event_id as string} fencerName={row.original.fencer_name as string} />);
};


export const directElimsCell: CellFunc = ({ value, row }: { value: string | number, row: any }): JSX.Element => {
    return (<DirectElims eventId={row.original.event_id as string} fencerName={row.original.fencer_name as string} />);
};


export const fencerLink = (fencerName: string | undefined): JSX.Element => {
    if (fencerName) {
    return (<StyleNavLink to={"/fencer/" + fencerName}>{fixAmp(fencerName)}</StyleNavLink>);
    } else {
        return (<></>);
    }
}

export const fencerCell: CellFunc = ({ value }: { value: string | number }): JSX.Element => {
    return fencerLink(value?.toString());
};


export const clubLink = (clubName: string): JSX.Element => {
    return (<StyleNavLink to={"/club/" + clubName}>{fixAmp(clubName?.toString())}</StyleNavLink>);
}


export const googleLink = (searchStr: string, caption: string): JSX.Element => {
    return (<StyleNavLink to={`https://google.com/search?q=${searchStr}`} target="_blank">{fixAmp(caption)}</StyleNavLink>);
}


export const eventResultCell: CellFunc = ({ value, row }: { value: string | number, row: Row }): JSX.Element => {
    const link = "/event_results/" + (row.original as any).event_id;
    return (<a className={LINK_CLASS_NAME} href={link}>{fixAmp(value.toString())}</a>);
};


export const clubCell: CellFunc = ({ value }: { value: string | number }): JSX.Element => {
    return clubLink((value || '').toString());
};
