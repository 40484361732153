import { makeAutoObservable } from "mobx"
import { SingleValue } from "react-select";
import { toOpt } from "../components/Selector";
import { v4 as uuidv4 } from 'uuid';


export const DEFAULT_CRAWL_URL = "https://fencingtimelive.com/rounds/seeding/891488F4CC35405FB46512D31BB91BD2/A732A71EBEDD47AEB3C353ADCE831707";

export const estWinOptions = toOpt(["Historical based on ELO difference", "ELO calculated"]);
export const useEloOptions = toOpt(["ELO from start of event", "Current ELO"]);

export const runUntilOptions = toOpt(["1 Iteration", "Highest Tableau", "Highest Pools", "Lowest Tableau", "Lowest Pools"]);




export class UIStateManager {
    // Tools
    toolsActiveTab = 0;
    watchEventActiveTab = 0;

    // Simulator
    simNumIter = "1000";
    simEstWinSel = estWinOptions[0];
    simUseEloSel = useEloOptions[0];
    simAutoRun = false;
    simAutoShowResults = false;
    simStepperJobId: string | undefined = uuidv4();
    simStepRunUntil = runUntilOptions[0];
    simStepAutoScroll = true;
    simRawText = '';


    constructor() {
        makeAutoObservable(this);
    }

    setToolsActiveTab(index: number) {
        this.toolsActiveTab = index;
    }

    setWatchEventActiveTab(index: number) {
        this.watchEventActiveTab = index;
    }

    setSimNumIter(iter: string) {
        this.simNumIter = iter;
    }

    setSimEstWinSel(selection: SingleValue<{ value: any; label: string; }>) {
        this.simEstWinSel = selection!;
    }
    
    setSimUseEloSel(selection: SingleValue<{ value: any; label: string; }>) {
        this.simUseEloSel = selection!;
    }

    setSimAutoRun(autoRun: boolean) {
        this.simAutoRun = autoRun;
    }

    setSimAutoShowResults(autoShow: boolean) {
        this.simAutoShowResults = autoShow;
    }

    setSimStepperJobId(jobId: string | undefined) {
        this.simStepperJobId = jobId;
    }

    setSimStepRunUntil(runUntil: SingleValue<{ value: any; label: string; }>) {
        this.simStepRunUntil = runUntil!;
    }

    setSimStepAutoScroll(autoScroll: boolean) {
        this.simStepAutoScroll = autoScroll;
    }

    setSimRawText(rawText: string) {
        this.simRawText = rawText;
    }
};


export const uiState = new UIStateManager();
