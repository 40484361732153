import { SimProps, SimulationResults } from "./sim_types";
import { ShowIf } from "../ShowIf";
import { VirtualList } from "../VirualList";
import { SizeContainer } from "../SizeContainer";
import { uiState } from '../../stores/ui_store';
import { useRemoteJobRunner } from "./remote_job_runner";


export interface SimRunTabProps {
    jobId?: string;
    simProps?: SimProps;
    onShowResults: (results: SimulationResults) => void;
    onComplete: (results: SimulationResults) => void;
}


export function SimRunTab(props: SimRunTabProps) {
    const { jobId, onComplete, onShowResults, simProps } = props;

    const onRemoteComplete = (result: SimulationResults) => {
        if (uiState.simAutoShowResults) {
            // Auto-click
            uiState.setSimAutoShowResults(false);
            onShowResults(result);
        }
    };

    const { apiStatus, apiResult, apiError } = useRemoteJobRunner<SimulationResults>(jobId!, "simulator", JSON.stringify(simProps), onRemoteComplete);

    const showResultsClicked = () => {
        if (apiResult) {
            onShowResults(apiResult);
        }
    };

    return (
        <>
            <ShowIf show={apiError}>
                <div className="flex-0 bg-red-500">Error {apiError}</div>
            </ShowIf>
            <SizeContainer className="flex-1 overflow-hidden flex flex-col">
                <VirtualList items={apiStatus || []} />
            </SizeContainer>
            <div className="flex-0 flex flex-wrap justify-center">
                <input
                    onClick={showResultsClicked}
                    defaultValue="Show Results"
                    className={`pt-2 pb-2 pr-2 pl-2 cursor-pointer ${(apiResult) ? "bg-cyan-300" : "bg-gray-300"}`}
                    type="Submit"
                />
            </div>
        </>
    )
}
