import { groupBy } from 'underscore';
import { Tournament } from './Tournament';
import { compareRow, convertDate, createFilter, fixAmp, placeStrToNum } from './helpers';
import { useState, useEffect } from 'react';
import { Selector } from './Selector';
import { LINK_CLASS_NAME } from './CellHelpers';


const MAX_SHOW_ROWS = 5000;


interface TournamentSetProps {
    data: {}[];
    loading: boolean;
    onShowMore: () => void;
}


const createFilters = (setFilters: any, setSelections: any, rows: any[]) => {
    const newData = rows.map((d: any) => {
        d.oneDate = convertDate(d.tourney_date);
        d.placeFixed = placeStrToNum(d.place);
        d.name = d.fencer_name;
        d.event = d.event_name;
        d.tourney = d.tourney_name;
        d.tourneyDate = d.tourney_date;
        d.tourneyLoc = d.tourney_location;
        d.numFencer = d.num_fencer;
        return d;
    });
    newData.sort(compareRow);

    const event = createFilter(newData, 'event');
    const club1 = createFilter(newData, 'club1');
    const name = createFilter(newData, 'name');
    const tourney = createFilter(newData, 'tourney');

    const newFilters = { event, club1, name, tourney };
    setFilters(newFilters);

    const selNames = [];
    for (let oneClub of club1) {
        if (oneClub.toLowerCase().includes("windy") && oneClub.toLowerCase().includes("city")) {
            selNames.push(oneClub);
        }
    }

    const sels = {
        club1: selNames.map(clubName => { return { value: clubName, label: fixAmp(clubName) } })
    };
    setSelections(sels);
}

export const TournamentSet = (props: TournamentSetProps) => {
    const { data, loading } = props;
    const [selections, setSelections] = useState({} as any);
    const [filters, setFilters] = useState({} as any);

    useEffect(() => {
        createFilters(setFilters, setSelections, data);
    }, [data]);

    const filteredData = data.filter((row: any) => {
        let result = true;

        for (let key of Object.keys(filters)) {
            const val = (row[key] || '').replace("&amp;", "&");
            const selAry = Array.from(selections[key] || []);
            let matchesOne = false;
            for (let oneSel of selAry) {
                if (val === (oneSel as any).value) {
                    matchesOne = true;
                }
            }
            if (!matchesOne && selAry.length > 0) {
                return false;
            }
        }

        return result;
    });

    const showData = filteredData.slice(0, MAX_SHOW_ROWS);

    const tourneyGroups: any = groupBy(showData, (val: any) => val.tourney + "//" + val.tourneyDate);

    const onClickShowMore = () => {
        props.onShowMore();
    }

    return (
        <div>
            <div className='mb-4 pb-2 pt-2 bg-gray-200 flex justify-center'>
                <div className='flex flex-col text-center'>
                    <div className='text-3xl'>Fencing Tournaments Report</div>
                    <div>
                        {(loading) ?
                            <>
                                Loading data, please wait...
                            </> :
                            <>
                                <span className="mr-4">Total data rows: {data?.length}</span>
                                <span className="mr-4">Filtered data rows: {filteredData?.length}</span>
                                <span className="mr-4">
                                    <a className={LINK_CLASS_NAME} onClick={onClickShowMore}>Show More Rows</a>
                                </span>
                            </>
                        }
                    </div>
                </div>
            </div>

            <div className='flex flex-wrap justify-center'>
                <Selector
                    label="Tournaments"
                    fieldName="tourney"
                    options={filters["tourney"]}
                    value={selections["tourney"]}
                    selections={selections}
                    setSelections={setSelections}
                />
                <Selector
                    label="Events"
                    fieldName="event"
                    options={filters["event"]}
                    value={selections["event"]}
                    selections={selections}
                    setSelections={setSelections}
                />
                <Selector
                    label="Clubs"
                    fieldName="club1"
                    options={filters["club1"]}
                    value={selections["club1"]}
                    selections={selections}
                    setSelections={setSelections}
                />
                <Selector
                    label="Names"
                    fieldName="name"
                    options={filters["name"]}
                    value={selections["name"]}
                    selections={selections}
                    setSelections={setSelections}
                />
            </div>

            {Object.keys(tourneyGroups).map(tourneyKey => {
                const tourneyData = tourneyGroups[tourneyKey];
                const tourneyName = tourneyData[0]?.tourney;
                return (
                    <Tournament name={tourneyName} data={tourneyData} key={tourneyKey} />
                )
            })}
        </div>
    );
}
