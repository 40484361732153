import moment from 'moment';
import { uniq } from 'underscore';
import { SqlRow } from './ToolCrawl';


console.log('helpers - create new cache');
const cache = {} as { [key: string]: any };       // Since its a cache I guess its ok to just be a local const


export interface FetchSQLResult {
    rows?: SqlRow[];
    err?: string;
    status?: any;
}


export const rawFetchSql = async (postData: any): Promise<FetchSQLResult> => {
    console.log('rawFetchSql', postData.queryName, postData.queryParams);
    const response = await fetch("/api/runQuery", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(postData) });
    try {
        const json = await response.json();
        return { rows: json?.queryResults?.rows };
    }
    catch (err) {
        return { err: (err as any).message };
    }
}


const getSqlCacheKey = (postData: any): string => {
    const allHash = Object.assign({}, { queryName: postData.queryName, ...postData.queryParams });
    return getDictCacheKey(allHash);
}


const getDictCacheKey = (dict: any): string => {
    const allHashKeys = Object.keys(dict).sort();
    const allHashVals = allHashKeys.map((key: string) => dict[key]);
    return allHashVals.join("__/__");
}


export const fetchSql = async (postData: any): Promise<FetchSQLResult> => {
    const cacheKey = getSqlCacheKey(postData);
    // TODO - expire cache based on time & size, etc.
    if (!cache[cacheKey]) {
        cache[cacheKey] = await rawFetchSql(postData);
    }
    return cache[cacheKey];
}


export interface ApiFetchResult {
    logLines?: string[];
    err?: any;
    result?: any;
}

export const apiFetch = async (path: string, postData: any): Promise<ApiFetchResult> => {
    const response = await fetch(`/api/${path}`, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(postData) });
    try {
        const json = await response.json();
        return json;
    }
    catch (err) {
        return { err: (err as any).message };
    }
}


export const fixAmp = (s: string | number): string => {
    return (s || '').toString().replace("&amp;", "&");
}

export const createFilter = (data: any[], key: string, doSort = true) => {
    const result = uniq(data.map(row => (row[key] || '').replace("&amp;", "&")));
    if (doSort) {
        result.sort();
    }
    return result;
}


export function convertDate(s: string): string {
    if (s.includes(' - ')) {
        const p = s.split(" - ");
        s = p[1];
    }

    const md = moment(s, 'MMM D, YYYY');
    return md.format('YYYY-MM-DD');
}


export function placeStrToNum(s: string): number {
    return parseInt(s.replace("T", ""));
}


export function compareRow(l1: any, l2: any): number {
    if (l1.oneDate !== l2.oneDate) {
        return (l1.oneDate < l2.oneDate) ? 1 : -1;
    }
    if (l1.event !== l2.event) {
        return (l1.event < l2.event) ? -1 : 1;
    }
    const p1 = placeStrToNum(l1.place);
    const p2 = placeStrToNum(l2.place);
    if (p1 !== p2) {
        return p1 - p2;
    }
    if (l1.name !== l2.name) {
        return (l1.name < l2.name) ? -1 : 1;
    }
    return 0;
}
