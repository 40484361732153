
export interface CheckboxProps {
    caption: string;
    checked: boolean;
    className?: string;
    enabled?: boolean;
    onClick: (newChecked: boolean) => void;
}


export const Checkbox = (props: CheckboxProps) => {
    const { caption, checked, className, enabled, onClick } = props;

    const onChange = () => {
        // This is a controlled component, so controller is responsible for changing "checked" state
        const newChecked = !checked;
        onClick(newChecked);
    }

    return (
        <div className={`cursor-pointer select-none ${className}`}>
            <label>
                <input type="checkbox" onChange={onChange} checked={checked}/>
                <span className={`cursor-pointer select-none ml-2`}>{caption}</span>
            </label>
        </div>
    );
};
